import {loadAllData} from "./ethereum"
import {connect} from "../../utils/ethereum"

export const userConnect = ()=>{
  return dispatch =>{
    connect(dispatch);
    loadAllData()(dispatch);
  }
}

export const userLogout =()=>{
  return dispatch =>{
    dispatch({type:"USER_LOGOUT"});
  }
}

