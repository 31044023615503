import React,{useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Claim from '../../component/Claim'
import Position from '../../component/Position'
import Transaction from '../../component/Transaction'
import TokenDetail from '../../component/Tokens/TokenDetail'

import {getFarmInfo} from "../../services/actions"
import {addAlpha} from "../../utils/index"


const Details = (props) => {
  const vaultKey = props.match.params.key;
  const { user, farms } = props;
  const network_key = user.network.key;

  const farm = farms[network_key].find(v => v.key == vaultKey);

  useEffect(()=>{
    let interval = setInterval(()=>{
      props.getFarmInfo(farm,user);
    },30*1000);

    return ()=>{
        clearInterval(interval);
      }
  },[])

  useEffect(()=>{
    let fun =() => {
       props.history.push('/farms');
    }
    window.addEventListener('network_change', fun);
    return ()=>{
      window.removeEventListener('network_change', fun);
    }
  },[])

  return (
    <>
      <section className="card_area_1 py-5" style={{background:`linear-gradient(180deg, rgb(255 255 255 / 34%) 51.02%, ${addAlpha(farm.bg_color,0.1)} 56.86%, ${addAlpha(farm.bg_color,0.2)} 62.7%, ${addAlpha(farm.bg_color,0.2)} 68.53%, ${addAlpha(farm.bg_color,0.3)} 74.37%, ${addAlpha(farm.bg_color,0.3)} 80.21%, ${addAlpha(farm.bg_color,0.3)} 80.21%, ${addAlpha(farm.bg_color,0.3)} 84.17%, ${addAlpha(farm.bg_color,0.3)} 88.13%, ${addAlpha(farm.other_color,0.2)} 92.08%, ${addAlpha(farm.other_color,0.2)} 96.04%, ${addAlpha(farm.other_color,0.5)} 100%)`}}>
        <div className="container ">
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <TokenDetail farm={farm} user={user}/>
            </div>
            <div className="col-lg-8">

              <Claim  farm={farm}/>
              {farm.my_stake_actual > 0 ?
              <Position  farm={farm}/>
              :
              <br/>
              }

              <Transaction  farm={farm}/>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}



Details.propTypes = {
  user: PropTypes.object,
  farms: PropTypes.object,
  getFarmInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  farms: state.farms,
})

const mapDispatchToProps = {
  getFarmInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
