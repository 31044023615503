import React from 'react'
import FarmsCard from '../Farms/FarmsCard'
import NoTransaction from '../Transaction/NoTransaction';


const FarmsSection = (props) => {
  const {farms,project} = props;

  var farmCards = farms.map((vaultObj,i) => {
    return (
      <div className="col-lg-3" key={i}>
        <FarmsCard farm={vaultObj} />
      </div>
    );
  })

  return (
    <>
       <section className="project-title" style={{ background:project.bottom_bg}} >
        <div className="container">
          <div className="row">


          {farms.length < 1  ?
          
          <NoTransaction msg_txt = "No Farms are currently available  :( " />
        : 
        
        farmCards
        }



            
          </div>
        </div>
      </section>
    </>
  )
}

export default FarmsSection
