import React,{useState} from 'react'

import share from '../../assets/images/share.png'

import {etherUrl,shortTxAddress} from "../../utils/ethereum";
import {unixTime} from "../../utils/index";

const TransactionData = (props) => {
  const {vault,history,user} = props;
  const [page,setPage] = useState(1);
  const perPage = 10;

  const changePage = (p)=>{
    if(p >=1 && p <=totalPage()){
      setPage(p);
    }
  }

  const totalPage = ()=>{
      return Math.ceil(history.length/perPage);
  }

  const currentPageData = ()=>{
    let data = [];
    let start = (page-1)*perPage;
    let end =  start+perPage > history.length ?  history.length : start+perPage;
    for(let i=start; i< end ; i++ ){
      data.push(history[i]);
    }
    return data
  }
  

  return (
    <>
    <div className="table-responsive">
    <table className="table table-striped erc_transaction_table">

<thead>
  <tr>
    <th scope="col">Date</th>
    <th scope="col">action</th>
    <th scope="col">{vault.off_chain ? "Token ID" : "Amount"}</th>
    <th scope="col">address</th>
    {/* <th scope="col"><button className="download_btn">Download csv</button></th> */}
  </tr>

</thead>
<tbody>
  {vault.off_chain ?
    <>
     {currentPageData().map((v,i)=>{
        return (
          <tr className="tr_border" key={i}>
            <td>{unixTime(new Date(Date.parse(v.createdAt)).getTime() / 1000 )}</td>
            <th className="font-16"> {v.eventType}</th>
            <td>{v.inputTokenId > 0 ? v.inputTokenId : "-"}</td>
            <td> <a href={etherUrl(user.network,"/address/"+v.account)} target="_blank" className="text-decoration-underline" style={{color:"#1F2D3D"}}>{shortTxAddress(v.account)}
              &nbsp;<img src={share}/>
            </a> </td>
          </tr>
        )
      })}
    </>
  :
  <>
  {currentPageData().map((v,i)=>{
    return (
      <tr className="tr_border" key={i}>
        <td>{unixTime(v.timestamp)}</td>
        <th className="font-16"> {v.event}</th>
        <td> -- </td>
        <td> <a href={etherUrl(user.network,"/tx/"+v.transactionHash)} target="_blank" className="text-decoration-underline" style={{color:"#1F2D3D"}}>{shortTxAddress(v.transactionHash)}
          &nbsp;<img src={share}/>
        </a> </td>
      </tr>
    )
  })}
  </>
}

</tbody>
</table>

    </div>
    <div className="d-flex justify-content-center
             align-items-center ">
      <div className="pagination align-items-center">
        <a  className={page==1 ? "pagination_back_btn pointer":"pagination_next_btn pointer"} onClick={()=>{changePage(page-1)}} >❮ <span>Back</span></a>
        <div className="page_number"> {page} </div>
        <a  className={page==totalPage() ? "pagination_back_btn pointer":"pagination_next_btn pointer"} onClick={()=>{changePage(page+1)}} ><span>Next</span> ❯ </a>
      </div>
      </div>
    </>
  )
}

export default TransactionData
