import {DOMAIN_NAME} from "../../index"
const DeapSeaAngler = {
  key:"angler",  
  name:"Deep Sea Anglerz",
  shortDetails:"Deep Sea Anglerz are a timeless collective of artwork from some of the most talented illustrators in the world.",
  details:"Deep Sea Anglerz are a timeless collective of artwork from some of the most talented illustrators in the world.  When you take the leap into the deep blue, you are joining an exclusive community committed to the preservation of our oceans.",
  logo_image:DOMAIN_NAME+"/assets/images/projects/angler/logo.svg",
  small_banner:DOMAIN_NAME+"/assets/images/projects/angler/banner.png",
  big_banner:DOMAIN_NAME+"/assets/images/projects/angler/big_banner.png",
  website:"https://deepseaanglerz.com/",
  twitter:"deepseaanglerz",
  discord:"HFY7vYBrBs",
  lite_paper:"",
  telegram:"",
  bg_color:"#cde4e53b",
  other_color:"#D4ECE9",
  top_bg:"#CDE4E5",
  bottom_bg:"#F3F5F8",
  total_vaults:1,
  total_farms:1,
  total_nfts: 1,
  network:{
    eth_prod:{ 
      vaults:[{ 
        key:"angler_1",
        off_chain:true,
        perBlock:43200,
        name:"Deep Sea Anglerz",
        p_key:"angler",  
        website:"https://deepseaanglerz.com/",
        details:"This vault allows users to stake Deep Sea Anglerz NFTs to earn DEEP token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/angler/banner.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/angler/reward.svg",
        bg_color:"#cde4e53b",
        other_color:"#D4ECE9",
        rewards_per_day: "",
        total_capacity:"8888",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"Deep Sea Anglerz",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/angler/logo.svg",
        reward_address:"0xEe417713cc962Edfd456B7E1f6E0AD5391a01991",
        reward_decimal:"6",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/angler/reward.svg",
        reward_symbol:"DEEP",
        pool_id: 1,
        pool_nft_addr:"0xe441237FEBDed90B3E6F77aCA0F2Eba62283e4Aa",
        vault_addr: "0xe441237FEBDed90B3E6F77aCA0F2Eba62283e4Aa",
        off_chain_id:"0xe441237FEBDed90B3E6F77aCA0F2Eba62283e4Aa",
        reward_claim_addr: "0x41971fE0eB79581FEaCbe7c4E1A07bD934533beA",
        status:true
      }],
       farms:[],
      tokens:[],
      nfts:[],
      status:true
    },
    avalanche_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    polygon_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    eth_dev:{ 
      vaults:[{ 
        key:"angler_1",
        off_chain:true,
        perBlock:43200,
        name:"Deep Sea Anglerz",
        p_key:"angler",  
        website:"https://deepseaanglerz.com/",
        details:"This vault allows users to stake Deep Sea Anglerz NFTs to earn DEEP token.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/angler/banner.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/angler/reward.svg",
        bg_color:"#cde4e53b",
        other_color:"#D4ECE9",
        rewards_per_day: "",
        total_capacity:"8888",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1616516851",
        nft_name:"Deep Sea Anglerz",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/angler/logo.svg",
        reward_address:"0xd88CcD0B00C887E1f9fFC4A56e3aeCC5896CD499",
        reward_decimal:"6",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/angler/reward.svg",
        reward_symbol:"MNDL",
        pool_id: 1,
        pool_nft_addr:"0x28aed794742a574670a1ead16d90e056fb96aa9c",
        vault_addr: "0x28aed794742a574670a1ead16d90e056fb96aa9c",
        off_chain_id:"0x28aed794742a574670a1ead16d90e056fb96aa9c",
        reward_claim_addr: "0x481aba6fc47a6b1d718e6e59a6a0708347944115",
        status:true
      }],
      farms:[],
      tokens:[],
      nfts:[],
      status:true
    },
    avalanche_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    polygon_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    }
  },
  status:true
}

export default DeapSeaAngler;
