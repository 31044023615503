export function  userReducer(state = {login:false,data:false,network:"",message:""}, action){
  switch (action.type) {
    case "USER_LOGOUT":
      return {login:false,data:false,network:"",message:""};
    case "USER_SUCCESS":
      return {...action.data,message:""}
    case "USER_NETWORK_ERROR":
      return {...action.data,message:""}
    case "USER_NETWORK_CHANGE":
      return {...action.data,message:""}
    case "USER_ERROR":
      return {login:false,data:false,network:"",message:action.message}  
  }
  return state;
}


export function  userMyDataReducer(state = {vaults:false,farms:false}, action){
  switch (action.type) {
    case "USER_MYDATA_RESET":
      return {vaults:false,farms:false};
    case "USER_MYDATA_SUCCESS":
      return action.data;
  }
  return state;
}