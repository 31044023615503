export function  projectsReducer(state = {}, action){
  let obj;
  switch (action.type) {
    case "PROJECT_REQUEST":
      obj ={...state}
      obj[action.network_key] = action.data;
      return obj;
    case "PROJECT_SUCCESS":
      obj ={...state}
      obj.type = "success"
      obj[action.network_key] = action.data;
      return obj; 
  }
  return state;
}

export function  vaultsReducer(state = {}, action){
  let obj;
  switch (action.type) {
    case "VAULTS_REQUEST":
      obj ={...state}
      obj.type = "request"
      obj[action.network_key] = action.data;
      return obj;
    case "VAULTS_SUCCESS":
      obj ={...state}
      obj.type = "success"
      obj[action.network_key] = action.data;
      return obj; 
    case "VAULT_UPDATE":
      obj ={...state}
      obj[action.network_key].map((v,i)=>{
        if(v.key == action.key){
          obj[action.network_key][i] = action.data;
        }
      })
      return obj;
  }
  return state;
}

export function farmsReducer(state = {}, action){
  let obj;
  switch (action.type) {
    case "FARMS_REQUEST":
      obj ={...state}
      obj.type = "request"
      obj[action.network_key] = action.data;
      return obj;
    case "FARMS_SUCCESS":
      obj ={...state}
      obj.type = "success"
      obj[action.network_key] = action.data;
      return obj; 
    case "FARM_UPDATE":
      obj ={...state}
      obj[action.network_key].map((v,i)=>{
        if(v.key == action.key){
          obj[action.network_key][i] = action.data;
        }
      })
      return obj;
  }
  return state;
}

export function fixRewardReducer(state = {}, action){
  let obj;
  switch (action.type) {
    case "FIX_REWARD_REQUEST":
      obj ={...state}
      obj.type = "request"
      obj[action.network_key] = action.data;
      return obj;
    case "FIX_REWARD_SUCCESS":
      obj ={...state}
      obj.type = "success"
      obj[action.network_key] = action.data;
      return obj; 
    case "FIX_REWARD_UPDATE":
      obj ={...state}
      obj[action.network_key].map((v,i)=>{
        if(v.key == action.key){
          obj[action.network_key][i] = action.data;
        }
      })
      return obj;
  }
  return state;
}