import React,{useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CustomButton } from '../UIElements/Buttons'

import Toast from "../Toast";

import {getFarmInfo} from "../../services/actions"
import {unStakeTokens,etherUrl} from "../../utils/ethereum"
import {addAlpha} from "../../utils/index"

const ClaimTab = (props) => {
  const {farm,user} = props;
  const [tostInfo,setTostInfo] = useState({type:"",title:"",message:""});
  const [stakingFlag,setStakingFlag] = useState(false);

  const unstackRewards=(flag)=>{
    setStakingFlag(true);
    unStakeTokens(farm,user.data.eth_address,(data)=>{
      setStakingFlag(false);
      if(data.type == "success"){
        props.getFarmInfo(farm,user);
        setTostInfo({type:"success",title:"Claim Success",message:`Tokens unstaked successfully. <a href="${etherUrl(user.network,'/tx/'+data.data.transactionHash)}" target="_blank"> Click Here</a>`});
      }else{
        setTostInfo({type:"error",title:"Claim Failed",message:data.message});
      }
    })
  }


  return (
    <>
      <div className="claim_tab_2">
        <div className="claim_tab_2_content" style={{background:`linear-gradient(90deg, ${addAlpha(farm.bg_color,0.2)} 0%,  ${addAlpha(farm.other_color,0.2)} 98.54%)`}}>
          <div className="card-body pt-4">
            <div className="claim_balance">
              <h5> TOTAL CLAIMABLE</h5>
              <h3> {farm.my_stake_actual <= 0 ? "0" : farm.my_rewards} {farm.reward_symbol}</h3>

            </div>

            {farm.my_stake_actual <= 0 ?
            <div className="stake_token_block mb-3  d-flex justify-content-between">
              <h5 className="w-50 pe-5">You need to stake tokens in order to claim rewards</h5>


              <button  onClick={()=>{props.setCurrentTab("first")}}>Stake Tokens</button>

            </div>
            :
            <>
            <CustomButton className={stakingFlag ? "disable_btn mt-3" : "eneable_btn mt-3"} disabled={stakingFlag} onClick={unstackRewards}> {stakingFlag ? "Unstake & Claiming all rewards..." : "Unstake & Claim ALL REWARDS"} </CustomButton>
            </>
        }
          </div>

        </div>
      </div>
      {tostInfo.message != "" &&
        <Toast type={tostInfo.type} title={tostInfo.title} message={tostInfo.message} close={()=>{setTostInfo({type:"",title:"",message:""})}}/>
      }
    </>
  )
}


ClaimTab.propTypes = {
  user: PropTypes.object,
  farms: PropTypes.object,
  getFarmInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user : state.user,
  farms : state.farms,
})

const mapDispatchToProps = {
  getFarmInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTab);