import React, { useState,useEffect } from 'react'
import { withRouter } from "react-router-dom";


import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom';
import CustomToolTip from '../UIElements/CustomToolTip'

import px from "../../assets/images/token_icons/px.svg"
import gPX from "../../assets/images/token_icons/gPX.svg"
import profile from "../../assets/images/profile.png"
import logo from "../../assets/images/logo.svg"

import chainData from "../../services/constants/chains"
import { userConnect, userLogout } from "../../services/actions"
import { shortEthAddress, walletSwitchEthereumChain } from "../../utils/ethereum"

import NetworkError from '../ModalBox/NetworkError';
import Toast from "../Toast";

import { addMetaMaskAssets } from "../../utils/ethereum"
import { DOMAIN_NAME } from "../../services/constants"
import { getRewardInfo } from "../../services/actions"

// import drop_down from "../"

const Header = (props) => {
  const { user,fixReward } = props;
  const network_key = user.network.key;
  const currentReward = fixReward[network_key]



  useEffect(() => {
    if(user.login){
      if(currentReward.length > 0){ 
        props.getRewardInfo(currentReward[0], user);
      }
    }
  }, [currentReward])

  const changeChain = (chain) => {
    walletSwitchEthereumChain(chain.chainId, (data) => {
      if (data.type == "error") {
        // show tost of error
      }
    })
  }

  const addToken = (obj) => {
    addMetaMaskAssets(obj, (data) => {
      // handle error!
    })
  }

  const myBtnClick = () => {
    props.history.push('/my-data');
  }

  return (
    <div>

      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <Link className="navbar-brand  logo_section " to="/"><img src={logo} /></Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/projects" className={window.location.href.includes("project") ? "nav-link  header_menu_active vault_menu" : "nav-link header_menu"} aria-current="page" style={{ borderLeft: "2px solid" }}>Projects </Link>

              </li>
              <li className="nav-item">
                <Link to="/vaults" className={window.location.href.includes("vault") ? "nav-link border-start-0 header_menu_active vault_menu" : "nav-link border-start-0 header_menu"} href="#vault_menu">Vaults</Link>

              </li>
              <li className="nav-item">
                <Link to="/farms" className={window.location.href.includes("farm") ? "nav-link border-start-0 header_menu_active vault_menu" : "nav-link border-start-0 header_menu"} >Farms</Link>
              </li>
              {(user.login && currentReward.length > 0 && (currentReward[0].my_rewards > 0 || (currentReward[0].unclaimedRewards && currentReward[0].unclaimedRewards.length > 0 ))) &&
                <li className="nav-item">
                  <Link to="/claim-reward" className={window.location.href.includes("claim-reward") ? "nav-link border-start-0 header_menu_active vault_menu" : "nav-link border-start-0 header_menu"} >Claim Reward</Link>
                </li>
              }

            </ul>
            <span className="navbar-text">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center right-nav">
                {/* <li className="nav-item">
                  <CustomToolTip tooltip_msg="Add PX To MetaMask">
                    <a className="nav-link  px-1 active" aria-current="page" href="#"  onClick={()=>{addToken({address:"0x9750766bf2a6dac0e4a2b427b870ead034259921",symbol:"TV1PG",decimals:"18",image:DOMAIN_NAME+"/assets/images/tokens/px.svg"})}}>
                      <img src={px} /></a>
                  </CustomToolTip>


                </li>
                <li className="nav-item  px-1">
                  <CustomToolTip tooltip_msg="Add PX To MetaMask">
                    <a className="nav-link px-1" href="#"  onClick={()=>{addToken({address:"0x4C9825C17570672A5c7C1cBbF4D9C9Ed35642989",symbol:"PolyientX",decimals:"18",image:DOMAIN_NAME+"/assets/images/tokens/gPX.svg"})}}><img src={gPX} /></a>
                  </CustomToolTip>


                </li> */}
                {user.login ?
                  <>
                    <li className="nav-item  px-3 network_selection ">
                      {/* <DropdownButton id="dropdown-basic-button" title={user.network.short_name} className="mainet_btn_dropdown network_se">
                      {chainData.map((v,i)=>{
                        return (<Dropdown.Item onClick={()=>{changeChain(v)}} key={i}>{v.short_name}</Dropdown.Item>)
                      })}
                      
                    </DropdownButton> */}
                      <div className="nav-link metamask_btn  d-flex align-items-center" >
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-button-dark-example1 " className="mainet_btn_dropdown px-1 py-2 network_se">
                            <span>{user.network.short_name} </span>
                            <img src="/assets/images/icon/drop_down.svg" className="px-2" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="">
                            {chainData.map((v, i) => {
                              return (<Dropdown.Item onClick={() => { changeChain(v) }} key={i}>{v.short_name}</Dropdown.Item>)
                            })}
                          </Dropdown.Menu>
                        </Dropdown>

                      </div>

                    </li>
                    <li className="nav-item ">
                      <div className="nav-link metamask_btn  d-flex align-items-center" >
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-button-dark-example11" className="px-1" >
                            <span>{shortEthAddress(user.data.eth_address)}</span>  <img src={profile} width="30" className="rounded profile_img ms-2" />
                            <img src="/assets/images/icon/drop_down.svg" className="px-2" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu variant="">
                            <Dropdown.Item onClick={myBtnClick}> Your Vaults & Farms </Dropdown.Item>
                            <Dropdown.Item onClick={props.userLogout}>Sign Out</Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  </>
                  :
                  <>
                    <li className="nav-item  px-1">
                      <button className="connect_metamask_btn" onClick={props.userConnect}>Connect </button>
                    </li>
                  </>
                }
              </ul>
            </span>
          </Navbar.Collapse>
        </div>
      </Navbar>


      {user.network === false &&
        <NetworkError close={() => { props.userLogout() }} />
      }


      {user.message != "" &&
        <Toast type="error" title="Error!" message={user.message} close={() => { props.userLogout() }} />
      }


    </div>
  )
}



Header.propTypes = {
  user: PropTypes.object,
  userConnect: PropTypes.func,
  userLogout: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  fixReward: state.fixReward,
})

const mapDispatchToProps = {
  userConnect,
  userLogout,
  getRewardInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
