import React from 'react'
import PropTypes from 'prop-types'

import Header from "../component/Header";
import Footer from "../component/Footer";

import LoadNft from "../component/LoadNft";

export function LayoutOne(props) {

  return (
    <>
     <Header />
      {props.children}
      <LoadNft/>
     <Footer />
    </>
  )
}

LayoutOne.propTypes = {

}

export function LayoutTwo(props) {
  
  return (
    <>
      <Header />
      <div className="container">
        {props.children}
      </div>
    </>
  )
}

LayoutTwo.propTypes = {

}
