import React,{useState} from 'react'
import { Tab, Nav } from 'react-bootstrap'
import ClaimTab from './ClaimTab'
import Stake from './Stake'
const Claim = (props) => {
  const [currentTab,setCurrentTab] = useState('first');
  const setTab =(tab)=>{
    setCurrentTab(tab)
  }
  const {farm} = props;
  return (
    <>
      <div className="card w-100 stack_card claim_table_section p-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first" onSelect={setTab} activeKey={currentTab}>
          <Nav className="d-flex bd-highlight  tab_section d-flex align-items-center">
            <Nav.Item className=" bd-highlight tb_option">
              <Nav.Link eventKey="first">STAKE Tokens</Nav.Link>
            </Nav.Item>
            <Nav.Item className=" bd-highlight tb_option">
             <Nav.Link eventKey="second">CLAIM rewards</Nav.Link>
            </Nav.Item>
            {/* <div className="ms-auto p-2 bd-highlight tb_txt"><span>Stake Tokens To Earn Rewards</span></div> */}
          </Nav>

          <div className="card-body p-0 stack_body">
            <Tab.Content className="">
              <Tab.Pane eventKey="first">
                <Stake farm={farm} />
              </Tab.Pane>
              <Tab.Pane eventKey="second" >
                <ClaimTab farm={farm} setCurrentTab={setTab}/>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>


    </>
  )
}

export default Claim
