import { combineReducers } from 'redux';

import { userReducer, userMyDataReducer } from "./user"
import { projectsReducer, vaultsReducer, farmsReducer,fixRewardReducer } from "./ethereum"

const rootReducer = combineReducers({
    user: userReducer,
    userData: userMyDataReducer,
    projects: projectsReducer,
    vaults: vaultsReducer,
    farms: farmsReducer,
    fixReward: fixRewardReducer,
});

export default rootReducer;
