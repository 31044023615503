import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-bootstrap/Toast'

import checkMark from '../../assets/images/check.png'
import cross from '../../assets/images/cross.svg'
import neutral from '../../assets/images/neutral.svg'

function ToastUi(props) {
  const [showTime,setShowTime] = useState(props.showTime || 10)

  useEffect(()=>{
    let timeOut= setTimeout(()=>{
      close();
    },showTime*1000);

    return ()=>{
      clearTimeout(timeOut);
    }
  })

  const close = ()=>{
    props.close();
  }

  return (
    <div style={{position:"fixed",right:"0", top:"10px",zIndex:"1000"}}>
      {props.type =="success" && 
        <Toast onClose={()=>{close()}} className="staking_success_toast m-4">
          <Toast.Header>
            <img src={checkMark} className="rounded me-2" alt="" />
            <strong className="me-auto">{props.title}</strong>
            {props.time ?<small>11 mins ago</small> : null}
          </Toast.Header>
          <Toast.Body dangerouslySetInnerHTML={{__html: props.message}}></Toast.Body>
        </Toast>
      }

      {props.type =="error" && 
        <Toast onClose={()=>{close()}} className="staking_failed_toast m-4">
          <Toast.Header>
            <img src={cross} className="rounded me-2" alt="" />
            <strong className="me-auto">{props.title}</strong>
            {props.time ?<small>11 mins ago</small> : null}
          </Toast.Header>
          <Toast.Body dangerouslySetInnerHTML={{__html: props.message}}></Toast.Body>
        </Toast>
      }

    {(props.type !="success" &&  props.type !="error" ) &&
          <Toast onClose={()=>{close()}} className="staking_neutral_toast m-4">
            <Toast.Header>
              <img src={neutral} className="rounded me-2" alt="" />
              <strong className="me-auto">{props.title}</strong>
              {props.time ?<small>11 mins ago</small> : null}
            </Toast.Header>
            <Toast.Body dangerouslySetInnerHTML={{__html: props.message}}></Toast.Body>
          </Toast>
    }
    </div>
  )
}

ToastUi.propTypes = {

}

export default ToastUi

