import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'

import {getVaultInfo} from "../../services/actions"

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const VaultsCard = (props) => {
  const {vault,vaults,user} = props;
  const network_key = user.network.key; 

  const vaultObj = vaults[network_key].find(v=>v.key == vault.key);


  useEffect(()=>{
      props.getVaultInfo(vaultObj,user);
      
      let interval = setInterval(()=>{
        props.getVaultInfo(vaultObj,user);
      },30*1000);

      return ()=>{
        clearInterval(interval);
      }
  },[])

  const bg_color=()=>{
    if(vaultObj.my_stake > 0){
      return "card-footer vault_footer bg-transparent";
    }else{
      return "card-footer vault_footer_default bg-transparent";
    }
  }

  const box_view=()=>{
    
      if(vaultObj.my_stake > 0){
        return "card no_shadow_card hover_card my-2 thick_border";
      }

      if(vaultObj.my_stake == 0 && vaultObj.my_nft == 0){
        // return "card no_shadow_card hover_card my-2 set_opacity";
      }
      return "card no_shadow_card hover_card my-2"
  }

  return (
    <>
     <Link to={`/vault/${vaultObj.key}/details`}  className="t_card_link">
        <div className={box_view()}>
          <div className="card-body p-0 token_detail">
            <div className="vault_img" style={{backgroundImage:`url(${vaultObj.banner_img})`,backgroundSize:"cover"}}>
            
            </div>
            <div className="vaults_action  vaults_bg " style={{background:`linear-gradient(180deg, ${vaultObj.bg_color} 0%, ${vaultObj.other_color} 100%)`}}>
               <button className="btn_1"><img src={vaultObj.reward_icon} className="pe-2" width="30" />{vaultObj.reward_symbol}</button>
               
              {vaultObj.my_stake > 0 ? (<button className="btn_2">Staked</button>) : ''}

              <h1>{vaultObj.name}</h1>
            </div>
          </div>


          <div className={bg_color()}>
            <div className="card_footer_1 vault_card_footer_1 py-2  d-flex  justify-content-between">
              <div>
                <label className="">REWARDS / NFT</label>
                <p className="text-uppercase">
                {!vault.off_chain ? 
                  <>{vaultObj.rewards_per_day}  </>
                  :
                  <>  {vault.total_stake > 0 ? vault.per_day : ""} </>
                  }
                  </p>
              </div>
              <div className="text-end">
                <label className="">CAPACITY</label>
                <p className="text-uppercase">{vaultObj.total_stake} of {vaultObj.total_capacity}</p>
              </div>

            </div>
            <div className="card_footer_2 py-2   d-flex  justify-content-between">
              <div>
                <label className="">EARNED REWARDS</label>
                <h6 className="text-start">{vaultObj.my_rewards}</h6>
              </div>
              <div>
                <label className="text-end">STAKED NFTS</label>
                <h6 className="text-end">{vaultObj.my_stake}</h6>
              </div>

            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

VaultsCard.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user:state.user,
  vaults:state.vaults,
})

const mapDispatchToProps = {
  getVaultInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(VaultsCard)
