import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ProjectCard from '../../component/Projects/ProjectCard'
import TitleBar from '../../component/TitleBar'
import NoTransaction from '../../component/Transaction/NoTransaction'

const Projects = (props) => {
  const {user,projects} = props;
  const network_key = user.network.key; 

  var projectsCard = projects[network_key].map((project,i) => {
    return (
        <div className="col-lg-3" key={i}>
          <ProjectCard project={project}/>
        </div>
    )
  })

  return (
    <>

      <TitleBar className="title_bar" title="All Projects" sub_title="" description="" />

      <section className="card_area py-5">
        <div className="container">
          <div className="row">
            {projects[network_key].length < 1 ? 
                <NoTransaction msg_txt = "No projects are currently available  :( " />
            :
            
            <>
            
            {projectsCard}
                  
            </>
            
            }
          </div>
        </div>
      </section>
    </>
  )
}



Projects.propTypes = {
  user: PropTypes.object,
  projects: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user,
  projects: state.projects
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);