import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import StackNFTModal from "../ModalBox/StackNFTModal"
import Hand from "../../assets/images/hand.png"

import { CustomButton } from '../UIElements/Buttons'
import Toast from "./../Toast";

import { getVaultApproval, getVaultInfo,resetVaultMyNFTS,enableStaking,enableManyStaking } from "../../services/actions"
import { stakeNfts, etherUrl,web3SoliditySha3,validateAddress } from "../../utils/ethereum";

const Stake = (props) => {
  const { vault, vaults, user } = props;
  const [approveProcess, SetApproveProcess] = useState(false);

  const [chooseNFT, SetChooseNFT] = useState(false);
  const [selectNft, setSelectNft] = useState([]);

  const [tostInfo, setTostInfo] = useState({ type: "", title: "", message: "" });
  const [stakingFlag, setStakingFlag] = useState(false);

  const closeNftStake = (flag = false) => {
    SetChooseNFT(false);
    if (flag !== true) {
      setSelectNft([]);
    }
  }

  const enableStakeNftsBtn = () => {
    setStakingFlag(true);
    // let data = [{ type: 'address', value: vault.pool_nft_addr },
    // { type: 'uint256', value: selectNft[0].toString() }];

    // for staking many
    let manyTokens =selectNft.map((v)=>{return {"tokenContract":vault.pool_nft_addr,"tokenId":v.toString()}});
    let data = {
      "account": validateAddress(user.data.eth_address),
      "vaultId": vault.off_chain_id,
      "tokenInputs": manyTokens
     }

     console.log("data-as-object",data);
     data = JSON.stringify(data);

     console.log("data-as-json-stringify",data);
     // end staking many
   

    web3SoliditySha3(data,user.data.eth_address,(data)=>{
      if(data.type=="success"){
        console.log("web3SoliditySha3",data.data);

        let stakeInfo = {
          "account": validateAddress(user.data.eth_address),
          "vaultId": vault.off_chain_id,
          "tokenContract": vault.pool_nft_addr,
          "tokenId": selectNft[0].toString(),
          "signature": data.data.signature
         }


         let stakeInfoMany = {
          "account": validateAddress(user.data.eth_address),
          "vaultId": vault.off_chain_id,
          "tokenInputs": manyTokens,
          "signature": data.data.signature
         }

         console.log("Api Object",stakeInfoMany);

        //  enableManyStaking

        enableManyStaking(stakeInfoMany,(type,data)=>{
        //  enableStaking(stakeInfo,(type,data)=>{
          let error = data.message || data.reason ;
          setStakingFlag(false);
          if(!error){ 
            props.getVaultInfo(vault, user);
            props.resetVaultMyNFTS(vault, user);
            setTostInfo({ type: "success", title: "Staking Success", message: `${selectNft.length} NFT staked successfully. ` });
            closeNftStake();
          }else{
            console.log("Data",data);
            setTostInfo({ type: "error", title: "Staking Failed", message: error });
          }
         })

      }else{
        setStakingFlag(false);
      }
      
    })

  }

  const stakeNftsBtn = () => {
    setStakingFlag(true);
    stakeNfts(selectNft, vault, user.data.eth_address, (data) => {
      setStakingFlag(false);
      if (data.type == "success") {
        props.getVaultInfo(vault, user);
        props.resetVaultMyNFTS(vault, user);
        setTostInfo({ type: "success", title: "Staking Success", message: `${selectNft.length} NFTS staked successfully. <a href="${etherUrl(user.network, '/tx/' + data.data.transactionHash)}" target="_blank"> Click Here</a>` });
        closeNftStake();
      } else {
        setTostInfo({ type: "error", title: "Staking Failed", message: data.message });
      }
    })
  }

  const approveStake = () => {
    SetApproveProcess(true);
    props.getVaultApproval(vault, user, (data) => {
      if (data.type == "error") {
        setTostInfo({ type: "error", title: "Error!", message: data.message });
      }
      SetApproveProcess(false);
    });
  }

  const viewNft=()=>{
    return vault.my_nft>1 ? "NFTS" : "NFT";
  }

  return (
    <>
      <div className="tab_first px-3 pb-4" style={{minHeight:"220px"}}>
        {chooseNFT &&
          <div className="d-flex justify-content-center pb-4">
            <img src={Hand} width="200" className="hand_gestures" />
          </div>
        }
        <br />
        <div className={vault.my_nft == 0 ? "claim_balance set_opacity mb-3 " : "claim_balance mb-3"} >
          <h5>  {vault.off_chain ? "Eligible NFTs" : "STAKEABLE NFTS"}</h5>
          <h3> {vault.my_nft} </h3>
        </div>

        {vault.my_nft == 0 ?
          <CustomButton className=" accent_btn_disable set_opacity font-18" disabled={true}>No Compatible {viewNft()}</CustomButton>
          :
          <>
            {(vault.is_approved || vault.off_chain) ?
              <>
                {selectNft.length == 0 ?
                  <CustomButton className="eneable_btn w-100 font-24" onClick={() => { SetChooseNFT(true) }}> {vault.off_chain ? "Enable Rewards" : (vault.my_stake > 0 ? 'STAKE MORE '+viewNft() : 'CHOOSE '+viewNft())} </CustomButton>
                  :
                  <>
                  <div className="d-flex two_button_state" >
                    {stakingFlag ?
                      <div className="w-100 p-1">
                        <CustomButton className="eneable_btn  text-white set_opacity w-100 font-24" onClick={stakeNftsBtn} disabled={stakingFlag}>  {vault.off_chain ? (stakingFlag ? `Enabling...` : `Enable Rewards (${selectNft.length})`) : (stakingFlag ? `STAKING...` : `STAKE (${selectNft.length})`)}</CustomButton>
                      </div>
                    :
                    <>
                    <div className="w-50 p-1">
                      {vault.off_chain ?
                        <> <CustomButton className="eneable_btn my-1 w-100 font-24" onClick={enableStakeNftsBtn} disabled={stakingFlag}>  {stakingFlag ? `Enabling...` : `Enable Rewards (${selectNft.length})`}</CustomButton></>
                      :
                        <> <CustomButton className="eneable_btn my-1 w-100 font-24" onClick={stakeNftsBtn} disabled={stakingFlag}>  {stakingFlag ? `STAKING...` : `STAKE (${selectNft.length})`}</CustomButton></>  
                      }
                     
                      </div>
                        <div className=" w-50 mt-1 p-1">
                        <CustomButton className="accent_btn w-100 font-24 " onClick={() => { SetChooseNFT(true) }}> Change NFTS </CustomButton>
                      </div>
                      </>
                    }

                  </div>
                   
                  </>
                }
              </>
              :
              <>
                <CustomButton className={approveProcess ? "  accent_btn w-100 font-24 set_opacity" : " accent_btn w-100 font-24"} disabled={approveProcess} onClick={approveStake}> {approveProcess ? "APPROVING ACCESS..." : "APPROVE ACCESS To STAKE"}</CustomButton>
                <div className="stack_area d-flex justify-content-center py-3 text-center">
                  <>You will need to sign an approval transaction to stake.</>
                </div>
              </>
            }
          </>
        }



      </div>

      {tostInfo.message != "" &&
        <Toast type={tostInfo.type} title={tostInfo.title} message={tostInfo.message} close={() => { setTostInfo({ type: "", title: "", message: "" }) }} />
      }

      {chooseNFT &&
        <StackNFTModal vault={vault} show={chooseNFT} handleClose={closeNftStake} selectNft={selectNft} setSelectNft={setSelectNft} />
      }


    </>
  )
}



Stake.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultApproval: PropTypes.func,
  getVaultInfo: PropTypes.func,
  resetVaultMyNFTS: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  vaults: state.vaults,
})

const mapDispatchToProps = {
  getVaultApproval,
  getVaultInfo,
  resetVaultMyNFTS
}

export default connect(mapStateToProps, mapDispatchToProps)(Stake);

