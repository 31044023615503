import React, { useState } from 'react'

const TitleBar = (props) => {

  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {

    setShowAlert(false);
  }

  const handleShowAlert = () => {

    setShowAlert(true)
  };
  return (
    <section className={props.className}>
      <div className="container">

        <div className="row  d-flex justify-content-between align-items-center">
          <div className="col-lg-6 col-sm-12">  <h1 className="w-100 my-2">{props.title}</h1></div>
          <div className="col-lg-6 col-sm-12 text-end title_detail" onClick={() => { handleShowAlert(1) }}><h6>{props.sub_title}</h6>
            <p className=" text-end">{props.description}</p></div>
        </div>


      </div>

    </section>
  )
}

export default TitleBar
