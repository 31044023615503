import React from 'react'
import CustomToolTip from '../UIElements/CustomToolTip'
import px from "../../assets/images/token_icons/px.svg"
import plus from "../../assets/images/plus.svg"


import {addMetaMaskAssets} from "../../utils/ethereum"
import {unixTimeMMDDYY,getDomainName } from "../../utils"
import {etherUrl} from "../../utils/ethereum"
 
const TokenDetail = (props) => {
  const { farm,user } = props;

  const addToken = ()=>{
    let obj = {address:farm.reward_address,symbol:farm.reward_symbol,decimals:farm.reward_decimal,image:farm.reward_icon}
    addMetaMaskAssets(obj,(data)=>{
      // handle error!
      console.log("data",data); 
    })
  }

  return (
    <>
      <div className="card no_shadow_card">
        <div className="card-body p-0 token_detail">
          <div className="img_holder" style={{backgroundImage:`url(${farm.banner_img})`,backgroundSize:"cover"}}>

          </div>
          <div className="token_data" style={{background:`linear-gradient(180deg, ${farm.bg_color} 0%, ${farm.other_color} 100%)`}}>
            <button>{farm.short_name}</button>
            <h1>{farm.name}</h1>
          </div>
        </div>


 


        <div className="card-footer bg-transparent ">

          <div className="card_footer_1 py-2  d-flex  justify-content-between">
            <div>
            <label className="detail_label text-end">Date Added</label>
              <p>{unixTimeMMDDYY(farm.created_at)}</p>
            </div>
            <div className="d-grid"> 
            {/* <label className="detail_label text-end">Date Added</label>
              <p>{unixTime(farm.created_at)}</p> */}
            </div>

          </div>
          <div className="card_footer_1 py-2   d-flex  justify-content-between">
            <div>
              <label className="detail_label ">LP Tokens In Farm</label>
              <p>{farm.my_stake} </p>
            </div>
            <div className="d-grid"> 
            <label className="detail_label text-end">Rewards (DAY)</label>
              <p className="text-end"> {farm.rewards_per_day}</p>
            </div>

          </div>
          <div className="card_footer_1 py-2   d-flex  justify-content-between">
            <div>
              <label className="detail_label  ">EARN</label>
              <div className="d-flex align-items-center" onClick={addToken}>
                <img src={farm.reward_icon}  width="20" className="me-1" />
                <u className="fst-italic" style={{textUnderlineOffset:"1px"}}>{farm.reward_symbol}</u>
            
                <CustomToolTip tooltip_msg={`Add ${farm.reward_symbol} To MetaMask`}>
                  <p className="m-0"><img src={plus} className="px-2" width="" /></p>
                </CustomToolTip>
              </div>
            </div>
            <div className="d-grid"> 
              <label className="detail_label text-end">Website</label>
              <p className="text-end" ><a href={farm.website} className="text-dark fst-italic" target="_blank">{getDomainName(farm.website)}</a></p>
            </div>

          </div>
          <div className="card_footer_2 m-auto py-4">
            {farm.buy_url!="" && 
              <a className="w-50 m-auto d-block py-3 text-center  buy_now_btn " href={farm.buy_url} target="_blank" style={{textUnderlineOffset:"1px"}}><i>Buy Token</i></a>
            }
          </div>
        
            {farm.dex_url!="" && 

              <div className="card_footer_2 py-2 pb-4">
                <a className="w-100 d-block py-3 text-center  buy_now_btn " href={farm.dex_url} target="_blank" style={{textUnderlineOffset:"1px"}}><i>ADD LIQUIDITY ON {farm.dex_name}</i></a>
              </div>
            }
          
          <div className="card_footer_2 py-2">
            <h4>About this Farm</h4>

            <p>{farm.details}</p>
            
          </div>
          <div className="card_footer_2 pt-2 pb-5">
            <h4>token contracts</h4>

            <p>
            <a className="address_link" href={etherUrl(user.network,"/token/"+farm.pool_token_addr)} target="_blank">{farm.name} Token</a> <br/>
            <a className="address_link" href={etherUrl(user.network,"/token/"+farm.reward_address)} target="_blank">{farm.reward_symbol} Token </a>
            </p>
          </div>

        </div>
      </div>


    </>
  )
}

export default TokenDetail
