import React from 'react'

import img_3 from '../../assets/images/img_3.png'
const NoTransaction = (props) => {
  return (
    <>

      <div className="container pb-5">
        <div className="row d-flex justify-content-center">
          <div className="col text-center">
            <img src={img_3} width="400" />
              <h4 className=" py-3 no_transection">{props.msg_txt}</h4>
          </div>
        </div>

      </div>
    </>
  )
}

export default NoTransaction
