import React from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom';

const ProjectCard = (props) => {
  const {project,user} = props;
  return (
    <>
      <Link to={{
                pathname:`/project/${project.key}/detail`, 
              }} className="t_card_link">
        <div className="card no_shadow_card hover_card my-2">
          <div className="card-body p-0 token_detail">
            <div className="project_image" style={{backgroundImage:`url(${project.small_banner})`}}>
            </div>
            <div className="vaults_action project_action " style={{background:`linear-gradient(180deg, ${project.other_color} 0%, ${project.bg_color} 100%)`}}>
              {/* <button className="btn_1">{project.network[user.network.key].vaults.length} VAULTS</button> */}

              <h1><img src={project.logo_image} width="65"/></h1>
            </div>
          </div>


          <div className="card-footer  bg-transparent ">

            <div className="card_footer_1 py-2  ">
              <div>
                <h4 className="heading_h1">{project.name}</h4>
              </div>

            </div>
            <div className="card_footer_2 py-2   ">

              <p style={{minHeight:"65px"}}>{project.shortDetails}</p>

              <div className="d-flex justify-content-center">
              <span className=" my-1 mx-2 badge rounded-pill bg-light text-dark count_badge">{project.network[user.network.key].vaults.length} {project.network[user.network.key].vaults.length > 1 ? "Vaults" : "Vault"}</span>
              <span className=" my-1 mx-2 badge rounded-pill bg-light text-dark count_badge">{project.network[user.network.key].farms.length} {project.network[user.network.key].farms.length > 1 ? "Farms" : "Farm"}</span>
              {/* <span className=" my-1 badge rounded-pill bg-light text-dark count_badge">10000 NFTS</span> */}
              </div>
             
            </div>



          </div>
        </div>

      </Link>


    </>
  )
}




ProjectCard.propTypes = {
  user: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCard);