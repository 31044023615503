// for making helper functions
export function ValidateEmail(email) 
{
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export async function   copyText(text){
  await window.navigator.clipboard.writeText(text); 
  alert("Address copied to clipboard!");
}


export function getDomainName(urlString){
  try{ 
    const url = new URL(urlString);
    let domain = url.host;
    domain = domain.replace('www.','');
    return domain;
  }catch(e){
    return urlString
  }

}

export function unixTime(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
};

export function unixTimeMMDDYY(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  // var month = months[a.getMonth()];
  var month = a.getMonth()+1;
  if(month.toString().length == 1){
    month ="0"+month.toString();
  }
  var date = a.getDate();
  if(date.toString().length == 1){
    date ="0"+date.toString();
  }
  var mdy= (month)+'/'+date+'/'+year
  // var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return mdy;
};


export function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

export function addAlpha(color, opacity){
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color.substr(0,7) + _opacity.toString(16).toUpperCase();
}

export function randomInteger(min, max){
  return Math.floor(Math.random() * (max - min + 1)) + min;
}