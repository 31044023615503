import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import ClaimTab from './ClaimTab'
import Stake from './Stake'
const ClaimErc = (props) => {
  const {vault} = props;
  return (
    <>
      <div className="card w-100 stack_card p-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav className="d-flex bd-highlight  tab_section d-flex align-items-center">
            <Nav.Item className=" bd-highlight tb_option" >
              <Nav.Link eventKey="first" className={props.underline ? "setUnderline" :"" } > {vault.off_chain ? "Earn Rewards" : "Stake NFTs"}</Nav.Link>
            </Nav.Item>
            <Nav.Item className=" bd-highlight tb_option" >
             <Nav.Link eventKey="second" className={props.underline ? "setUnderline" :"" }>Claim Rewards</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="card-body p-0 stack_body">
            <Tab.Content className="">
              <Tab.Pane eventKey="first">
                <Stake vault={vault}/>
              </Tab.Pane>
              <Tab.Pane eventKey="second" >
                <ClaimTab vault={vault}/>
              </Tab.Pane>
            </Tab.Content>
            {vault.my_stake > 25 &&
            <p className="m-4 text-center">
             <b><i>Note: You have more than 25 Staked NFTs which occasionally runs into staking/unstaking issues due to max gas limits. Please contact us in <a href="https://discord.com/invite/sxHnbaQe" target="_blank" style={{color:"black"}}>Discord</a> for directions on how to stake/unstake.</i></b>
            </p>
            }

          </div>
        </Tab.Container>
      </div>


    </>
  )
}

export default ClaimErc
