import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import TitleBar from '../../component/TitleBar'
import home from "../../assets/images/home.png"

import {userConnect} from "../../services/actions"


const Home = (props) => {
  return ( <>
      {/* <TitleBar className="disable_title_bar" title="All Vaults" sub_title="" description="" /> */}
      <section className=" card_area">
        <br/>
        <br/>
        <div className="container pb-5">

          <div className="row d-flex justify-content-center login_area">
            <div className="col-md-5 text-center login_box">
              <img src={home} width="200" />
              <h4 className="font-24 py-3">LOOKING FOR ALPHA?</h4>

              <button className="eneable_btn text-decoration-none " onClick={props.userConnect}> SIGN IN WITH METAMASK </button>
            </div>
          </div>

        </div>
      </section>
     
    </>
  )
}



Home.propTypes = {
  userConnect: PropTypes.func,
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  userConnect
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
