import React from 'react'
import CustomToolTip from '../UIElements/CustomToolTip'
import plus from "../../assets/images/plus.svg"

import {addMetaMaskAssets} from "../../utils/ethereum"
import {unixTimeMMDDYY,getDomainName} from "../../utils"
import {etherUrl} from "../../utils/ethereum"

const ErcTokenDetail = (props) => {
  const { vault,user } = props;

  const addToken = ()=>{
    let obj = {address:vault.reward_address,symbol:vault.reward_symbol,decimals:vault.reward_decimal,image:vault.reward_icon}
    addMetaMaskAssets(obj,(data)=>{
      // handle error!
      console.log("data",data);
    })
  }
  return (
    <>
      <div className="card no_shadow_card">
        <div className="card-body p-0 token_detail">
          <div className="img_holder" style={{backgroundImage:`url(${vault.banner_img})`,backgroundSize:"cover"}}>

          </div>
          <div className="erc_data" style={{background:`linear-gradient(180deg, ${vault.bg_color} 0%, ${vault.other_color} 100%)`}}>
          {vault.short_name!="" &&
            <button>  <img src={vault.icon_img} width="20" className="me-1" /> {vault.short_name}</button>
          }
            <h1>{vault.name}</h1>
          </div>
        </div>






        <div className="card-footer bg-transparent ">

          <div className="card_footer_1 py-2  d-flex  justify-content-between">
            <div>
              <label className="detail_label ">rewards / NFT</label>
              <p className="label_value">{!vault.off_chain ? 
              <>{vault.rewards_per_day} per day </>
              :
              <>  {vault.total_stake > 0  ? vault.per_day+" per day" : ""} </>
              }</p>
            </div>
            <div className="d-grid">
              <label className="detail_label text-end float-end">CAPACITY</label>
              <p className="label_value text-end float-end">{vault.total_stake} of {vault.total_capacity}</p>
            </div>

          </div>
          <div className="card_footer_1 py-2   d-flex  justify-content-between">
            <div>
            
              <label className="detail_label ">Created</label>
              <p className="label_value">{unixTimeMMDDYY(vault.created_at)}</p>
            </div>
            <div>
              {/* <label className="detail_label ">Wallets staked</label>
              <p className="text-end label_value"> {vault.my_stake}</p> */}
            </div>

          </div>
          <div className=" py-2   d-flex  justify-content-between">
            <div>
              <label className="detail_label ">EARN</label>
              <p className=" pointer" onClick={addToken}>
                  <img src={vault.reward_icon} width="20" className="me-1" style={{fontFamily:'Beatrice',fontWeight:'700'}}/>{vault.reward_symbol}
                <CustomToolTip tooltip_msg={`Add ${vault.reward_symbol} To MetaMask`}>
                  <img src={plus} className="px-2" width="30" />
                </CustomToolTip>
              </p>
            </div>
            <div className="d-grid">
              <label className="detail_label text-end float-end">WEBSITE</label>
              <p className="label_value pointer text-end" ><a href={vault.website} className="label_value"  target="_blank"><i>{getDomainName(vault.website)}</i></a></p>
            </div>

          </div>

          <div className="card_footer_2 py-2">
            <h4>{vault.off_chain ? "About The Rewards" :"About This Vault"}</h4>
            <p>{vault.details}</p>
      
          </div>
          <div className="card_footer_2 pt-2 pb-5">
            <h4>{vault.off_chain ? "Reward Contracts" :"Vault Contracts"}</h4>
            <p>
              <a className="address_link" href={etherUrl(user.network,(user.network.name.includes('Avalanchex')? "/erc721/":"/token/")+vault.pool_nft_addr+(user.network.name.includes('Avalanchex')? "/nft":""))} target="_blank">{vault.nft_name} NFT Contract</a> <br/>
              <a className="address_link" href={etherUrl(user.network,"/token/"+vault.reward_address)} target="_blank">{vault.reward_symbol} Token Contract </a>
            </p>
          </div>
  {/*         <div className="card_footer_2 pt-2 pb-5">
            <h4> RISKS</h4>
            <p>{vault.risk_txt}</p>
          </div>
 */}
        </div>
      </div>


    </>
  )
}

export default ErcTokenDetail
