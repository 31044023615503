import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Tab, Nav } from 'react-bootstrap'
import img_3 from '../../assets/images/img_3.png'
import share from '../../assets/images/share.png'
import NoTransaction from './NoTransaction'
import TransactionData from './TransactionData'

import {getContractEventLogs,getOffChainEventLogs} from "../../utils/ethereum"

const ErcTransaction = (props) => {
  const {vault,user} = props;

  const [txHistory,setTxHistory] = useState([]);
  const [myTxHistory,setMyTxHistory] = useState([]);
  const [txLoaded,setTxLoaded] = useState(false);

  useEffect(()=>{
    setTxLoaded(false);
    if(!vault.off_chain){
      getContractEventLogs(vault,user.data.eth_address,(data)=>{
        // setTxHistory(data.data);
        let mydata =[];
        let alldata =[];
        data.data.map((v,i)=>{
          if(v.event =="Staked" || v.event=="Unstaked"){ 
              alldata.push(v);   
            if(v.returnValues.user.toLocaleLowerCase() == user.data.eth_address.toLocaleLowerCase()){
              mydata.push(v);
            }   
          }   
        })

        setTxHistory(alldata);
        setMyTxHistory(mydata);
        setTxLoaded(true);
      })
   }else{
    getOffChainEventLogs(vault,user.data.eth_address,(data)=>{
      console.log("getOffChainEventLogs",data);
        setTxHistory(data.allReward.vaultEvents);
        setMyTxHistory(data.myReward.vaultEvents);
        setTxLoaded(true);
    })

   }


  },[])
  


  return (
    <>
      <div className="card w-100 no_shadow_card p-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav className="d-flex bd-highlight  tab_section d-flex align-items-center">
            <Nav.Item className=" bd-highlight tb_option transaction_tab  w-50 text-center">
              <Nav.Link eventKey="first">{vault.off_chain ? "Reward Transactions" :"Vault Transactions"}</Nav.Link>
            </Nav.Item>
            <Nav.Item className=" bd-highlight tb_option transaction_tab  w-50 text-center border-end-0">
             <Nav.Link eventKey="second">Your Transactions</Nav.Link>
            </Nav.Item>
          </Nav>


          <div className="card-body p-0 ">
            <Tab.Content className="">
              <Tab.Pane eventKey="first">
                <div className="transaction_tab_1 p-3">
                {txLoaded ?
                <>
                  {txHistory.length == 0 ? 
                    <NoTransaction  msg_txt = "This vault has no recent transactions :(" />
                  :
                    <TransactionData vault={vault} history={txHistory} user={user} />
                  }
                  </>
                  :
                  <>
                    <h3 className="text-center mt-5 mb-10"> Loading...</h3>
                    <br/>
                    <br/>
                  </>
                }

                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second" >
                <div className="transaction_tab_1 p-3">
                  {txLoaded ?
                  <>
                    {myTxHistory.length == 0 ? 
                      <NoTransaction  msg_txt = "This vault has no recent transactions :(" />
                    :
                      <TransactionData vault={vault} history={myTxHistory} user={user} />
                    }
                    </>
                    :
                    <>
                      <h3 className="text-center mt-5 mb-10"> Loading...</h3>
                      <br/>
                      <br/>
                    </>
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>

          </div>
        </Tab.Container>
      </div>

    </>
  )
}


ErcTransaction.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user : state.user,
  vaults : state.vaults,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ErcTransaction);
