import {DOMAIN_NAME} from "../../index"
const VevueProject = {
  key:"vevue",  
  name:"Vevue",
  shortDetails:"Vevue is a next-generation social media platform designed to reward you for sharing your most authentic self.",
  details:"Vevue is a next-generation social media platform designed to reward you for sharing your most authentic self. We are dedicated to empowering creativity. From feature films to restaurant reviews, we are returning control to content owners by changing how we interact with, distribute, watch, and appreciate videos.",
  logo_image:DOMAIN_NAME+"/assets/images/projects/vevue/logo.svg",
  small_banner:DOMAIN_NAME+"/assets/images/projects/vevue/banner.png",
  big_banner:DOMAIN_NAME+"/assets/images/projects/vevue/big_banner.png",
  website:"https://www.vevue.com/",
  marketplace:"https://galaxy.eco/vevue/campaign/GCmjeUUi44",
  twitter:"govevue",
  discord:"b7VK3pEtKb",
  lite_paper:"",
  telegram:"",
  bg_color:"#00d1de47",
  other_color:"#00d1de47",
  top_bg:"#E4E5F2",
  bottom_bg:"#F3F5F8",
  total_vaults:10,
  total_farms:10,
  total_nfts: 100,
  network:{
    eth_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    polygon_prod:{
      vaults:[{ 
        key:"vevue_1",
        off_chain:true,
        perBlock:43200,
        name:"Vevue",
        p_key:"vevue",  
        website:"https://www.vevue.com/",
        details:"This vault allows users to stake Vevue NFTs to earn the VPAY token on Polygon.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/vevue/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/vevue/reward.svg",
        bg_color:"#52ebf547",
        other_color:"#F3F5F8",
        rewards_per_day: "",
        total_capacity:"10000",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"Vevue",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/vevue/reward.svg",
        reward_address:"0xA0BDb7919c8Fff5165ee7b0fdFa318be2F8f3379",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/vevue/reward.svg",
        reward_symbol:"VPAY",
        pool_id: 1,
        pool_nft_addr:"0xEf99F27bbE7EBE3e50979cfc8DB8E5Fa2072621B",
        vault_addr: "0xEf99F27bbE7EBE3e50979cfc8DB8E5Fa2072621B",
        off_chain_id:"0xEf99F27bbE7EBE3e50979cfc8DB8E5Fa2072621B",
        reward_claim_addr: "0x464ff37C2E09dF6E7ded8438e2c74a07AABA1AA8",
        status:true
      }],
      farms:[],
      tokens:[],
      nfts:[],
      status:true
    },
    eth_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    polygon_dev:{
      vaults:[{ 
        key:"vevue_1",
        off_chain:true,
        perBlock:43200,
        name:"Vevue",
        p_key:"vevue",  
        website:"https://www.vevue.com/",
        details:"This vault allows users to stake Vevue NFTs to earn the VPAY token on Polygon.",
        short_name:"",
        banner_img:DOMAIN_NAME+"/assets/images/projects/vevue/card.png",
        icon_img:DOMAIN_NAME+"/assets/images/projects/vevue/logo.svg",
        bg_color:"#52ebf547",
        other_color:"#F3F5F8",
        rewards_per_day: "",
        total_capacity:"1122",
        total_stake:"",
        my_rewards:"",
        internal_bonus:"12065",
        my_stake:"",
        my_nft:"0",
        myNfts:false,
        created_at:"1644650635",
        nft_name:"Vevue",
        nft_icon: DOMAIN_NAME+"/assets/images/projects/vevue/logo.svg",
        reward_address:"0x9aDCEA06285d6c0c98E2765E7267F94E941Dce70",
        reward_decimal:"18",
        reward_icon:DOMAIN_NAME+"/assets/images/projects/vevue/logo.svg",
        reward_symbol:"VPAY",
        pool_id: 1,
        pool_nft_addr:"0x52b115587CFB34974d23ddb40F9B3Fcd2da56127",
        vault_addr: "0x52b115587CFB34974d23ddb40F9B3Fcd2da56127",
        off_chain_id:"0x52b115587CFB34974d23ddb40F9B3Fcd2da56127",
        reward_claim_addr: "0xCE71D5a3450891A8d3F1fb57EefEA407b3a5d278",
        status:true
      }],
      farms:[],
      tokens:[],
      nfts:[],
      status:true
    }
  },
  status:true
}

export default VevueProject;
