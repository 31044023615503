import React from 'react'
import px from "../../assets/images/token_icons/px.svg"


const ErcPosition = (props) => {
  const {vault} = props;
  return (
    <>

      <div className="card w-100 no_shadow_card erc_position my-5">
        <div className="card-header py-3  bg-transparent d-flex  justify-content-between align-items-center">
          <h1 className="position_heading">Current Position</h1>
          {/* <span className="badge position_badge ">You haven’t entered this vault yet</span> */}
        </div>
        <div className="card-body ">
          <div className="row position_count">
            <div className="text-center col-lg-4 col-md-6 col-sm-12 ">
              <label >NFTS STAKED</label>
              <h6>{vault.my_stake} </h6>


            </div>
            <div className="text-center col-lg-4 col-md-6 col-sm-12">
              <label >REWARDS SHARE</label>
              <h6>{Math.round((vault.my_stake/vault.total_stake)*100)}% </h6>

            </div>

            <div className="text-center col-lg-4 col-md-6 col-sm-12">
              <label >EARNED REWARDS</label>
              <h6>{vault.my_rewards} {vault.reward_symbol} </h6>

            </div>
          </div>
        </div>

      </div>


    </>
  )
}

export default ErcPosition
