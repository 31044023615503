import React,{useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TitleBar from '../../component/TitleBar'
import NoTransaction from '../../component/Transaction/NoTransaction'

import VaultsCard from '../../component/Vaults/VaultsCard'
import FarmsCard from '../../component/Farms/FarmsCard'


import {loadUserStakeInfo} from "../../services/actions"

const MyData = (props) => {
  const {user,userData,farms,vaults} = props;
  const network_key = user.network.key; 

  let myFarms =[];
  let myVaults =[];
  let VaultCards =[];
  let farmCards =[];

  useEffect(()=>{
    props.loadUserStakeInfo(user);
  },[])

  if(userData.vaults !==false){
    myVaults = vaults[network_key].filter(v=>userData.vaults.includes(v.key))
    
     VaultCards = myVaults.map((vaultObj,i) => {
        return (
          <div className="col-lg-3" key={i}>
            <VaultsCard vault={vaultObj} />
          </div>
        );
      })
  
  }

  if(userData.farms !==false){
    myFarms = farms[network_key].filter(v=>userData.farms.includes(v.key))

    farmCards = myFarms.map((vaultObj, i) => {
      return (
        <div className="col-lg-3" key={i}>
          <FarmsCard farm={vaultObj} />
        </div>
      );
    })

  }

  return (
    <>

      <TitleBar className="title_bar" title="Your Vaults & Farms" sub_title="" description=' ' />

      <section className="card_area py-5">
        <div className="container">
          <div className="row">
          {
            userData.farms === false ? 
            <>  
            <br/>
            <br/>
                <h1 className="text-center">Loading...</h1>
            </>
            :
            <>
            {(userData.farms.length == 0 && userData.vaults.length==0) ? 
               <> <NoTransaction msg_txt = "You are not currently staked into any Vaults or Farms  :( " />
                </>
            :
            <>
            {VaultCards} 
            <br/>
            {farmCards} 
            </>
            }
          </>
        }
          </div>
        </div>
      </section>
    </>
  )
}



MyData.propTypes = {
  user: PropTypes.object,
  userData: PropTypes.object,
  vaults: PropTypes.object,
  farms: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user:     state.user,
  userData: state.userData,
  vaults:   state.vaults,
  farms:    state.farms
})

const mapDispatchToProps = {
  loadUserStakeInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(MyData);