import React from 'react'

// export const AccentButton = (props) => {
//   return (
//     <>
//       <button className="accent_btn w-100">{props.children}</button>

//     </>
//   )
// }


// export const DisabledButton = (props) => {
//   return (
//     <>

//       <button className="w-100 ps-1 disable_btn font-18 p-4" disabled> {props.children}</button>
//     </>
//   )
// }


// export const PurpleButton = (props) => {
//   return (
//     <>
//       <button className="purple_btn p-4 w-100">{props.children}</button>

//     </>
//   )
// }



// export const BlueButton = (props) => {
//   return (
//     <>
//       <button className="w-100 py-3 my-1 stcked_eneable_btn"> {props.children}</button>
//     </>
//   )
// }


export const CustomButton = (props) => {
  return (
    <>
      <button className={props.className} disabled={props.disabled} onClick={props.onClick}> {props.children}</button>
    </>
  )
}