import React,{useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {getFarmInfo} from "../../services/actions"

import { Link } from 'react-router-dom';
const FarmsCard = (props) => {
  const {farm,farms,user} = props;
  const network_key = user.network.key; 

  const farmsObj = farms[network_key].find(v=>v.key == farm.key);

  useEffect(()=>{
      props.getFarmInfo(farmsObj,user);

      let interval = setInterval(()=>{
        props.getFarmInfo(farmsObj,user);
      },30*1000);

      return ()=>{
        clearInterval(interval);
      }

  },[])

  const bg_color=()=>{
    if(farm.my_stake > 0){
      return "card-footer vault_footer bg-transparent";
    }else{
      return "card-footer vault_footer_default bg-transparent";
    }
  }

  const box_view=()=>{
    
      if(farm.my_stake > 0){
        return "card  hover_card my-2 no_shadow_card thick_border";
      }

      if(farm.my_stake == 0 && farm.my_tokens_all == 0){
        // return "card  hover_card my-2 no_shadow_card set_opacity";
      }

      return "card  hover_card my-2 no_shadow_card"
  }


  return (
    <>
      <Link to={`/farms/${farmsObj.key}/details`} className="t_card_link">
        <div className={box_view()}>
          <div className="card-body p-0 token_detail">
            <div className="px_eth_img" style={{backgroundImage:`url(${farmsObj.banner_img})`}}>

            </div>
            <div className="vaults_action  px_eth_bg" style={{background:`linear-gradient(180deg, ${farmsObj.bg_color} 0%, ${farmsObj.other_color} 100%)`}}>
              <button className="btn_1"><img src={farmsObj.reward_icon} className="pe-2" width="30" />{farmsObj.reward_symbol}</button>


              {/* <button className="btn_2"><img src={farmsObj.t2_icon_img} width="20"/> {farmsObj.t2_name}</button> */}
              {parseFloat(farmsObj.my_stake_actual) > 0 ? (<button className="btn_2">Staked</button>) : ''}

              <h1>{farmsObj.name}</h1>
            </div>
          </div>


          <div className={bg_color()}>

            <div className="card_footer_1 vault_card_footer_1 py-2  d-flex  justify-content-between">
              <div>
                <label className="">REWARDS</label>
                <p className="text-uppercase">{farmsObj.rewards_per_day} </p>
              </div>
              <div className="text-end">
                {/* <label className="">APR/ APY</label>
                <p className="text-uppercase">{farmsObj.total_stake} of {farmsObj.total_capacity}</p> */}
              </div>

            </div>
            <div className="card_footer_2 py-2   d-flex  justify-content-between">
              <div>
                <label className="">EARNED REWARDS</label>
                <h6 className="text-start">{farmsObj.my_rewards}</h6>
              </div>
              <div>
                <label className="text-end">STAKED TOKENS</label>
                <h6 className="text-end">{farmsObj.my_stake}</h6>
              </div>

            </div>
          </div>
        </div>

      </Link>


    </>
  )
}



FarmsCard.propTypes = {
  user: PropTypes.object,
  farms: PropTypes.object,
  getFarmInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user:state.user,
  farms:state.farms,
})

const mapDispatchToProps = {
  getFarmInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmsCard)

