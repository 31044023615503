import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Tab, Nav } from 'react-bootstrap'

import CustomToolTip from '../../component/UIElements/CustomToolTip'
import Toast from "../../component/Toast";

import { CustomButton } from '../../component/UIElements/Buttons'

import {unixTimeMMDDYY,getDomainName } from "../../utils"

import {addMetaMaskAssets,claimRewards,claimPastRewards} from "../../utils/ethereum"

import { getRewardInfo } from "../../services/actions"


const ClaimReward = (props) => {
  const rewardKey = 'fix_1';
  const { user, fixReward } = props;
  const network_key = user.network.key;
  const reward = fixReward[network_key].find(v => v.key == rewardKey);

  const [stakingFlag,setStakingFlag] = useState(false);
  const [tostInfo,setTostInfo] = useState({type:"",title:"",message:""});


  useEffect(() => {
    let interval = setInterval(() => {
      props.getRewardInfo(reward, user);
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, [])


  
  const unStakeRewards=()=>{
    setStakingFlag(true);
    claimRewards(reward,user.data.eth_address,(data)=>{
      setStakingFlag(false);
      props.getRewardInfo(reward,user);
      if(data.type == 'success'){
        setTostInfo({type:"success",title:"Claim Success",message:`Reward claimed successfully.`});
      }else{
        setTostInfo({type:"error",title:"Claim Failed",message:data.message});
      }
    })
  }

  const unstackClaimRewards = ()=>{
    if(reward.off_chain){
      setStakingFlag(true);
      claimPastRewards(reward,user.data.eth_address,(data)=>{
        setStakingFlag(false);
        props.getRewardInfo(reward,user);
        if(data.type == 'success'){
          setTostInfo({type:"success",title:"Claim Success",message:`Reward claimed successfully.`});
        }else{
          setTostInfo({type:"error",title:"Claim Failed",message:data.message});
        }
      })
    }

  }
  
  const unstackRewards = ()=>{
    if(reward.off_chain){
      return unStakeRewards();
    }
    
  }

  const addToken = ()=>{
    let obj = {address:"0x938FE3788222A74924E062120E7BFac829c719Fb",symbol:"APEIN",decimals:18,image:"https://app.polyientx.com/assets/images/projects/ape/logo.svg"}
    addMetaMaskAssets(obj,(data)=>{
      // handle error!
      console.log("data",data);
    })
  }


  return (
    <>
    <section className="card_area_1 py-5"  style={{background: 'linear-gradient(rgba(255, 255, 255, 0.34) 51.02%, rgba(255, 219, 52, 0.1) 56.86%, rgba(255, 219, 52, 0.2) 62.7%, rgba(255, 219, 52, 0.2) 68.53%, rgba(255, 219, 52, 0.3) 74.37%, rgba(255, 219, 52, 0.3) 80.21%, rgba(255, 219, 52, 0.3) 80.21%, rgba(255, 219, 52, 0.3) 84.17%, rgba(255, 219, 52, 0.3) 88.13%, rgba(201, 212, 237, 0.2) 92.08%, rgba(201, 212, 237, 0.2) 96.04%, rgba(201, 212, 237, 0.5) 100%)'}}>
      <div className="container ">
        <div className="row ">
          <div className="col-lg-4 col-md-6 col-sm-12 ">
             {/* left panel */}
             <>
                <div className="card no_shadow_card">
                  <div className="card-body p-0 token_detail">
                  <div className="img_holder" style={{backgroundImage: 'url("https://app.polyientx.com//assets/images/tokens/ape_avax_banner.png")', backgroundSize: 'cover'}}>  </div>
                  <div className="token_data" style={{background: 'linear-gradient(rgba(255, 219, 52, 0.54) 0%, rgba(201, 212, 237, 0.54) 100%)'}}>
                    <h1>{reward.name}</h1>
                  </div>
              </div>


          


                  <div className="card-footer bg-transparent ">

                    <div className="card_footer_1 py-2  d-flex  justify-content-between">
                      <div>
                      <label className="detail_label text-end">Date Added</label>
                        <p>02/25/2022</p>
                      </div>
                      <div className="d-grid"> 
                     
                      </div>

                    </div>
                 
                    <div className="card_footer_1 py-2   d-flex  justify-content-between">
                      <div>
                        <label className="detail_label  ">EARN</label>
                        <div className="d-flex align-items-center" onClick={addToken}>
                          <img src="https://app.polyientx.com//assets/images/projects/ape/logo.svg"  width="20" className="me-1" />
                          <u className="fst-italic" style={{textUnderlineOffset:"1px"}}>APEIN</u>
                      
                          <CustomToolTip tooltip_msg={`Add APEIN To MetaMask`}>
                            <p className="m-0"><img src={"/static/media/plus.cae84b52.svg"} className="px-2" width="" /></p>
                          </CustomToolTip>
                        </div>
                      </div>
                      <div className="d-grid"> 
                        <label className="detail_label text-end">Website</label>
                        <p className="text-end" ><a href={"https://www.ape.in"} className="text-dark fst-italic" target="_blank">{getDomainName("https://www.ape.in")}</a></p>
                      </div>

                    </div>
                    <div className="card_footer_2 m-auto py-4">
                     
                    </div>
                  
                    
                    
                    <div className="card_footer_2 py-2">
                      <h4>About This Claim</h4>

                      <p>{reward.details}</p>
                      
                    </div>
                    <div className="card_footer_2 pt-2 pb-5">
                      <h4>token contracts</h4>

                      <p>
                      <a className="address_link" href={"https://snowtrace.io/token/0x938FE3788222A74924E062120E7BFac829c719Fb"} target="_blank">APEIN Token </a>
                      </p>
                    </div>

                  </div>
                </div>


              </>
             {/* end of panel */}
          </div>
          <div className="col-lg-8">

          <div className="card w-100 stack_card claim_table_section p-0">
            <Tab.Container id="left-tabs-example" defaultActiveKey="second">
              <Nav className="d-flex bd-highlight  tab_section d-flex align-items-center">
                <Nav.Item className=" bd-highlight tb_option">
                <Nav.Link eventKey="second">CLAIM rewards</Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="card-body p-0 stack_body">
                <Tab.Content className="">
                  <Tab.Pane eventKey="second" >
                  <>
                      <div className="claim_tab_2">
                        <div className="claim_tab_2_content" style={{background: 'linear-gradient(90deg, rgba(255, 219, 52, 0.2) 0%, rgba(201, 212, 237, 0.2) 98.54%)'}}>
                          <div className="card-body pt-4">
                            <div className="claim_balance">
                              <h5> EARNED REWARDS</h5>
                              <h3> {reward.my_rewards <= 0 ? "0" : (reward.my_rewards)} {reward.reward_symbol}</h3>

                            </div>

                            <>
                            {(reward.unclaimedRewards && reward.unclaimedRewards.length > 0 ) ?
                              <CustomButton className={stakingFlag ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag}  onClick={unstackClaimRewards}> {stakingFlag ? "Claiming past rewards..." : `Claim Past Rewards (${reward.unclaimedRewardAmount})`} </CustomButton>
                            :
                              <CustomButton className={(stakingFlag || reward.my_rewards <= 0) ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag || reward.my_rewards <= 0}  onClick={unstackRewards}> {stakingFlag ? "Claiming all rewards..." : "Claim All Rewards"} </CustomButton>
  }
                            </>
                          </div>

                        </div>
                      </div>
                      {tostInfo.message != "" &&
                        <Toast type={tostInfo.type} title={tostInfo.title} message={tostInfo.message} close={()=>{setTostInfo({type:"",title:"",message:""})}}/>
                      }
                    </>

                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>

          </div>
        </div>
      </div>
    </section>
  </>
  )
}

 
const mapStateToProps = (state) => ({
  user: state.user,
  fixReward: state.fixReward,
})

const mapDispatchToProps = {
  getRewardInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimReward);
