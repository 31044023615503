import { DOMAIN_NAME } from "../../index"
const RateProject = {
  key: "rate",
  name: "Rate Network",
  shortDetails: "Rate Network, powering the NFT market.",
  details: "Rate Network is building the first network dedicated to data, ratings and appraisals for the NFT asset class. Stake your qualifying NFTs to earn RATE.",
  logo_image: DOMAIN_NAME + "/assets/images/projects/rate/logo.svg",
  small_banner: DOMAIN_NAME + "/assets/images/projects/rate/card.png",
  big_banner: DOMAIN_NAME + "/assets/images/projects/rate/big_banner.png",
  website: "https://www.rate.network/",
  twitter: "rate_network",
  lite_paper: "https://docs.rate.network/",
  discord: "",
  telegram: "",
  bg_color: "#e8d2cd80",
  other_color: "#98ceee99",
  top_bg: "#ECE4E6",
  bottom_bg: "#EBE3E0",
  // below_color:"#EAE2DF",
  total_vaults: 10,
  total_farms: 10,
  total_nfts: 100,
  network: {
    eth_prod: {
      vaults: [
        {
          key: "rate_1",
          perBlock:7200,
          name: "Rate Series IV",
          p_key: "rate",
          website: "https://www.rate.network/ ",
          details: "Rate Network is building the first network dedicated to data, ratings and appraisals for the NFT asset class. Stake your qualifying NFTs to earn RATE.",
          short_name: "",
          banner_img: DOMAIN_NAME + "/assets/images/projects/rate/card.png",
          icon_img: DOMAIN_NAME + "/assets/images/projects/rate/logo.svg",
          bg_color: "#e8d2cd80",
          other_color: "#98ceee99",
          rewards_per_day: "",
          total_capacity: "80",
          total_stake: "",
          my_rewards: "",
          internal_bonus: "12065",
          my_stake: "",
          my_nft: "0",
          myNfts: false,
          created_at: "1618513489",
          nft_name: "Rate Series IV",
          nft_icon: DOMAIN_NAME + "/assets/images/projects/rate/logo.svg",
          reward_address: "0x507309a20014ea377f149fe28fe389fbfc6e0e4e",
          reward_decimal: "18",
          reward_icon: DOMAIN_NAME + "/assets/images/projects/rate/rate.svg",
          reward_symbol: "RATE",
          pool_id: 0,
          pool_nft_addr: "0xf6244D9b18fabf437BDd02495e100a33cFF02AB3",
          vault_addr: "0x634696e47b9af96f29952e1c7095f7462e9113e7",
          status: true
        }, {
          key: "rate_2",
          perBlock:7200,
          name: "Rate Series III",
          p_key: "rate",
          website: "https://www.rate.network/ ",
          details: "Rate Network is building the first network dedicated to data, ratings and appraisals for the NFT asset class. Stake your qualifying NFTs to earn RATE.",
          short_name: "",
          banner_img: DOMAIN_NAME + "/assets/images/projects/rate/card.png",
          icon_img: DOMAIN_NAME + "/assets/images/projects/rate/logo.svg",
          bg_color: "#e8d2cd80",
          other_color: "#98ceee99",
          rewards_per_day: "",
          total_capacity: "800",
          total_stake: "",
          my_rewards: "",
          internal_bonus: "12065",
          my_stake: "",
          my_nft: "0",
          myNfts: false,
          is_approved: false,
          created_at: "1619481825",
          nft_name: "Rate Series III",
          nft_icon: DOMAIN_NAME + "/assets/images/projects/rate/logo.svg",
          reward_address: "0x507309a20014ea377f149fe28fe389fbfc6e0e4e",
          reward_decimal: "18",
          reward_icon: DOMAIN_NAME + "/assets/images/projects/rate/rate.svg",
          reward_symbol: "RATE",
          pool_id: 0,
          pool_nft_addr: "0xc92270F7a4FB35FAb022ab6C87CbA7d8EdafD03c",
          vault_addr: "0x3c607d07e017bdf7204348ddb7cc010a665ea1d7",
          status: true
        }
      ],
      farms: [],
      tokens: [],
      nfts: [],
      status: true
    },
    avalanche_prod: {
      vaults: [],
      farms: [],
      tokens: [],
      nfts: [],
      status: false
    },
    polygon_prod: {
      vaults: [],
      farms: [],
      tokens: [],
      nfts: [],
      status: false
    },
    eth_dev: {
      vaults: [],
      farms: [],
      tokens: [],
      nfts: [],
      status: true
    },
    avalanche_dev: {
      vaults: [],
      farms: [],
      tokens: [],
      nfts: [],
      status: false
    },
    polygon_dev: {
      vaults: [],
      farms: [],
      tokens: [],
      nfts: [],
      status: false
    }
  },
  status: true
}

export default RateProject;
