import * as constants from "../constants"
import chainData from "../constants/chains"
import {getProjects,getVaults,getFrames,getFixRewards} from "../constants/projects"


import {vaultInfo,farmInfo,approveVault,approveFarm,loadNfts} from "../../utils/ethereum"
import {vaultStakeBalance, farmStakeBalance} from "../../utils/ethereum"

export const loadAllData = ()=>{
  return dispatch =>{
    chainData.map(v =>{
      dispatch({type:"PROJECT_SUCCESS",network_key:v.key,data:getProjects(v.key)});
      dispatch({type:"VAULTS_SUCCESS",network_key:v.key,data:getVaults(v.key)});
      dispatch({type:"FARMS_SUCCESS",network_key:v.key,data:getFrames(v.key)});
      dispatch({type:"FIX_REWARD_SUCCESS",network_key:v.key,data:getFixRewards(v.key)});
    })
  }
}

export const loadUserStakeInfo = (user)=>{
   return async dispatch =>{
     dispatch({type: 'USER_MYDATA_RESET'});
      let vaults = getVaults(user.network.key);
      let farms = getFrames(user.network.key);
      let myVaults = [];
      for (let index = 0; index < vaults.length; index++) {
        const v = vaults[index];
        let amount = await vaultStakeBalance(v,user.data.eth_address);
          if(amount > 0){
            myVaults.push(v.key)
          }
      }  

      let myFarms = [];
      for (let index = 0; index < farms.length; index++) {
        const v = farms[index];
         let amount = await farmStakeBalance(v,user.data.eth_address);
          if(amount > 0){
            myFarms.push(v.key)
          }
        }

      dispatch({type: 'USER_MYDATA_SUCCESS',data:{vaults:myVaults,farms:myFarms}}); 
   }
}

export function getFarmInfo(farm,user){
  return async(dispatch) => {
    let data = await farmInfo(farm,user.data.eth_address);
    dispatch({type: 'FARM_UPDATE',data:data,key:farm.key,network_key:user.network.key});
  } 
}

export function getFarmApproval(farm,amount,user,callback){
  return async(dispatch) => {
    try{
      let data = await approveFarm(farm,amount,user.data.eth_address);
      farm.allowance = amount;
      dispatch({type: 'FARM_UPDATE',data:farm,key:farm.key,network_key:user.network.key});
      callback({type:"success"})
    }catch(error){
      callback({type:"error",message:error.message})
    }
  } 
}

export function getVaultInfo(vault,user){
  return async(dispatch) => {
    let data = await vaultInfo(vault,user.data.eth_address);
    dispatch({type: 'VAULT_UPDATE',data:data,key:vault.key,network_key:user.network.key});
  } 
}

export function getRewardInfo(vault,user){

  return async(dispatch) => {
    let data = await vaultInfo(vault,user.data.eth_address);
    dispatch({type: 'FIX_REWARD_UPDATE',data:data,key:vault.key,network_key:user.network.key});
  } 
}

export function getVaultApproval(vault,user,callback){
  return async(dispatch) => {
    try{
      let data = await approveVault(vault,user.data.eth_address);
      vault.is_approved = true;
      dispatch({type: 'VAULT_UPDATE',data:vault,key:vault.key,network_key:user.network.key});
      callback({type:"success"})
    }catch(error){
      callback({type:"error",message:error.message})
    }
  } 
}

export function getVaultMyNFTS(vault,user,callback){
  return async(dispatch) => {
    try{
      let data = await loadNfts(vault,user.data.eth_address);
      vault.myNfts = data;
      dispatch({type: 'VAULT_UPDATE',data:vault,key:vault.key,network_key:user.network.key});
      callback({type:"success"})
    }catch(error){
      callback({type:"error",message:error.message})
    }
  } 
}


export function resetVaultMyNFTS(vault,user){
  return async(dispatch) => {
      vault.myNfts = false;
      dispatch({type: 'VAULT_UPDATE',data:vault,key:vault.key,network_key:user.network.key});
  } 
}

export function enableStaking(data,callback){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
};
  let url = constants.OFF_CHAIN_SERVER+'rewards/vault';
  fetch(url,requestOptions)
  .then(response => response.json())
  .then(json => callback('success',json))
  .catch(error => callback('error',error));
}

export function enableManyStaking(data,callback){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify(data)
};
  let url = constants.OFF_CHAIN_SERVER+'rewards/stake-many';
  fetch(url,requestOptions)
  .then(response => response.json())
  .then(json => callback('success',json))
  .catch(error => callback('error',error));
}