import React, {Suspense,StrictMode} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/style.css';

import { Provider } from "react-redux";
import configureStore from "./services/configureStore";

import { stopReportingRuntimeErrors } from "react-error-overlay";

import Routes from "./routes"

const store = configureStore();


// import ReactGA from "react-ga";

// for https redirect
if (process.env.REACT_APP_NODE_ENV =="production" && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

if (process.env.REACT_APP_NODE_ENV =="production" ) {
  stopReportingRuntimeErrors(); // disables error overlays
}


function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
            <Routes/>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;
