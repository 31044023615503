import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FarmsCard from '../../component/Farms/FarmsCard'
import TitleBar from '../../component/TitleBar'
import NoTransaction from '../../component/Transaction/NoTransaction'

const Farms = (props) => {
  const { user, farms } = props;
  const network_key = user.network.key;
  const allFarms = farms[network_key]

  var farmCards = allFarms.map((vaultObj, i) => {
    return (
      <div className="col-lg-3" key={i}>
        <FarmsCard farm={vaultObj} />
      </div>
    );
  })

  return (
    <>
      <TitleBar className="title_bar" title="All Farms" sub_title="" description="" />

      <section className="card_area py-5">
        <div className="container">
          <div className="row">



            {allFarms.length < 1
              ?
              <NoTransaction msg_txt="No Farms are currently available  :( " />
              :
              <>
                {farmCards}


              </>
            }


            { }

          </div>

        </div>
      </section>
    </>
  )
}



Farms.propTypes = {
  user: PropTypes.object,
  farms: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user,
  farms: state.farms,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Farms);
