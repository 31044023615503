import React,{useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ClaimRewardModal from "../ModalBox/ClaimRewardModal"
import Toast from "../Toast";

import { CustomButton } from '../UIElements/Buttons'
import {getVaultInfo,resetVaultMyNFTS} from "../../services/actions"
import {unStakeNfts,etherUrl,claimRewards,claimPastRewards,syncOffChainVaults} from "../../utils/ethereum"
import {setCookie,getCookie,addAlpha} from "../../utils/index"
const BigNumber = require('bignumber.js');


const ClaimTab = (props) => {
  const {vault,user} = props;
  const [showReward,SetShowReward] = useState(false);

  const [tostInfo,setTostInfo] = useState({type:"",title:"",message:""});
  const [stakingFlag,setStakingFlag] = useState(false);

  const closeClaimReward = ()=>{
    SetShowReward(false);
  }

  const unStakeRewards=()=>{
    setStakingFlag(true);
    claimRewards(vault,user.data.eth_address,async(data)=>{
     
      console.log("claimRewards-xx",data);
      try{
      let res = await syncOffChainVaults(parseInt(user.network.chainId),data.data.transactionHash);
      console.log("sync res",res);
      }catch(error){
          console.log("sync Error",error);
      }
      
      props.getVaultInfo(vault,user);
      if(data.type == 'success'){
        setTostInfo({type:"success",title:"Claim Success",message:`Reward claimed successfully.`});
      }else{
        setTostInfo({type:"error",title:"Claim Failed",message:data.message});
      }
      setTimeout(()=>{ setStakingFlag(false);},2000);
    })
  }

  const unstackClaimRewards = ()=>{
    if(vault.off_chain){
      setStakingFlag(true);
      claimPastRewards(vault,user.data.eth_address,async(data)=>{
        console.log("data-xx",data);
        console.log("data-xx",data.transactionHash);
        // setStakingFlag(false);
        try{
        let res = await syncOffChainVaults(parseInt(user.network.chainId),data.data.transactionHash);
        console.log("sync res",res);
        }catch(error){
            console.log("sync Error",error);
        }
        props.getVaultInfo(vault,user);
        if(data.type == 'success'){
          setTostInfo({type:"success",title:"Claim Success",message:`Reward claimed successfully.`});
        }else{
          setTostInfo({type:"error",title:"Claim Failed",message:data.message});
        }
        setTimeout(()=>{ setStakingFlag(false);},2000);
      })
    }

  }
  
  const unstackRewards = ()=>{
    if(vault.off_chain){
      return unStakeRewards();
    }
    let v = getCookie('claim_rewards');

    if(v =="true"){
      getAllRewards(false);
    }else{
      SetShowReward(true);
     
    }
    
  }

  const getAllRewards=(flag)=>{
    SetShowReward(false);
    if(flag){
      setCookie("claim_rewards","true",365);
    }
    setStakingFlag(true);
   
    
   unStakeNfts(vault,user.data.eth_address,(data)=>{
      props.resetVaultMyNFTS(vault, user);
      if(data.type == "success"){
        props.getVaultInfo(vault,user);
        setTostInfo({type:"success",title:"Claim Success",message:`NFTS unstaked successfully. <a href="${etherUrl(user.network,'/tx/'+data.data.transactionHash)}" target="_blank"> Click Here</a>`});
      }else{
        setTostInfo({type:"error",title:"Claim Failed",message:data.message});
      }
      setTimeout(()=>{ setStakingFlag(false);},2000);
    })
  }

  // let b_amount = new BigNumber(Math.floor(vault.my_rewards));
  // let tmp_amount = b_amount.toString();
  // b_amount = b_amount.dividedBy(new BigNumber(10**18))
  // let reward_amount = b_amount.toFixed();


  return (
    <>
      <div className="erc_claim_tab_2" style={{minHeight:"243px"}}>
        <div className="erc_claim_tab_2_content" style={{background:`linear-gradient(90deg, ${addAlpha(vault.bg_color,0.2)} 0%,  ${addAlpha(vault.other_color,0.2)} 98.54%)`}}>
          <div className="card-body">
            <div className="claim_balance mt-2 mb-3">
              <h5> EARNED REWARDS</h5>
              {vault.off_chain ?
              <h3> {vault.my_rewards <= 0 ? "0" : (vault.my_rewards)} {vault.reward_symbol}</h3>
               : 
               <h3> {vault.my_stake == 0 ? "0" : vault.my_rewards} {vault.reward_symbol}</h3> 
              }
              
            </div>
            {vault.my_rewards > 0  ?
              <>
              {vault.off_chain ? 
                <>
                {(vault.unclaimedRewards && vault.unclaimedRewards.length > 0 ) ?
                  <CustomButton className={stakingFlag ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag}  onClick={unstackClaimRewards}> {stakingFlag ? "Claiming past rewards..." : `Claim Past Rewards (${vault.unclaimedRewardAmount})`} </CustomButton>
                  :
                  <>
                  <CustomButton className={stakingFlag ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag}  onClick={unstackRewards}> {stakingFlag ? "Claiming all rewards..." : "Claim All Rewards"} </CustomButton>

                  {vault.my_rewards < 10 &&
                    <p className='text-center'><i><b>The amount of rewards to claim is relatively small. We recommend waiting to claim until more rewards are available. Visit back later!</b></i></p>
                  }
                  </>
                }
                </>
               :
              <>
              {(vault.my_stake  > 25) ?
              <CustomButton className={stakingFlag ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag}  onClick={unstackRewards}> {stakingFlag ? "Unstake(25) & Claiming all rewards..." : "Unstake(25) & Claim ALL REWARDS"} </CustomButton>
             :
             <CustomButton className={stakingFlag ? "eneable_btn  text-white set_opacity mt-1"  : "eneable_btn mt-1" } disabled={stakingFlag}  onClick={unstackRewards}> {stakingFlag ? "Unstake & Claiming all rewards..." : "Unstake & Claim ALL REWARDS"} </CustomButton>
              }
             </>
              }
             </>
            :
            <>
     
            <CustomButton  className=" accent_btn set_opacity mb-1  font-24" disabled={true}>NO REWARDS AVAILABLE TO CLAIM</CustomButton>
            </>
            }
          </div>

        </div>
      </div>
      {showReward && 
       <ClaimRewardModal vault={vault} show={showReward} handleClose={closeClaimReward}  getAllRewards={getAllRewards}/>
      }

      {tostInfo.message != "" &&
        <Toast type={tostInfo.type} title={tostInfo.title} message={tostInfo.message} close={()=>{setTostInfo({type:"",title:"",message:""})}}/>
      }
    </>
  )
}



ClaimTab.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultInfo: PropTypes.func,
  resetVaultMyNFTS: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user : state.user,
  vaults : state.vaults,
})

const mapDispatchToProps = {
  getVaultInfo,
  resetVaultMyNFTS
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimTab);