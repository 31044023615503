import {DOMAIN_NAME} from "../../index"
const Mandala = {
  key:"mandala",  
  name:"Crypto Mandala",
  shortDetails:"Crypto Mandala is an animated collection of 11,1111 NFTs on Avalanche blockchain.",
  details:"Crypto Mandala is an animated collection of 11,1111 NFTs on Avalanche blockchain. Each Crypto Mandala is a key to earn MNDL Tokens by staking mechanism. Both NFTs and Tokens can be used in different projects to open new gates.",
  logo_image:DOMAIN_NAME+"/assets/images/projects/mandala/logo.svg",
  small_banner:DOMAIN_NAME+"/assets/images/projects/mandala/banner.png",
  big_banner:DOMAIN_NAME+"/assets/images/projects/mandala/big_banner.png",
  website:"https://cryptomandala.art/",
  twitter:"CryptoMandalart",
  discord:"mr6A7qFrgA",
  lite_paper:"",
  telegram:"",
  bg_color:"#ffdb3400",
  other_color:"#c9d4ed00",
  top_bg:"#F4E2D9",
  bottom_bg:"#EFE4E9",
  total_vaults:1,
  total_farms:0,
  total_nfts: 1,
  network:{
    eth_prod:{ 
      vaults:[],
       farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_prod:{
      vaults:[
        { 
          key:"mandala_1",
          off_chain:true,
          perBlock:43200,
          name:"Crypto Mandala",
          p_key:"mandala",  
          website:"https://cryptomandala.art/",
          details:"This vault allows users to stake Crypto Mandala NFTs to earn MNDL token.",
          short_name:"",
          banner_img:DOMAIN_NAME+"/assets/images/projects/mandala/card.png",
          icon_img:DOMAIN_NAME+"/assets/images/projects/mandala/logo.svg",
          bg_color:"#F4E2D900",
          other_color:"#EFE4E9",
          rewards_per_day: "",
          total_capacity:"11111",
          total_stake:"",
          my_rewards:"",
          internal_bonus:"12065",
          my_stake:"",
          my_nft:"0",
          myNfts:false,
          created_at:"1616516851",
          nft_name:"Crypto Mandala",
          nft_icon: DOMAIN_NAME+"/assets/images/projects/mandala/logo.svg",
          reward_address:"0xd3df6106346ac91ce76d1adf291a68581d3fa782",
          reward_decimal:"6",
          reward_icon:DOMAIN_NAME+"/assets/images/projects/mandala/reward_icon.svg",
          reward_symbol:"MNDL",
          pool_id: 1,
          pool_nft_addr:"0x2dda3c00aee8c93946a209595b9c28fbfa2e50b4",
          vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
          off_chain_id:"0x2dda3c00aee8c93946a209595b9c28fbfa2e50b4",
          reward_claim_addr: "0x41971fE0eB79581FEaCbe7c4E1A07bD934533beA",
          status:true
        }
       ],
      farms:[],
      tokens:[],
      nfts:[],
      status:true
    },
    polygon_prod:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    },
    eth_dev:{ 
      vaults:[],
      farms:[ ],
      tokens:[],
      nfts:[],
      status:false
    },
    avalanche_dev:{
      vaults:[
        { 
           key:"mandala_1",
           off_chain:true,
           perBlock:43200,
           name:"Crypto Mandala",
           p_key:"mandala",  
           website:"https://cryptomandala.art/",
           details:"This vault allows users to stake Crypto Mandala NFTs to earn MNDL token.",
           short_name:"",
           banner_img:DOMAIN_NAME+"/assets/images/projects/mandala/card.png",
           icon_img:DOMAIN_NAME+"/assets/images/projects/mandala/logo.svg",
           bg_color:"#F4E2D900",
           other_color:"#EFE4E9",
           rewards_per_day: "",
           total_capacity:"8888",
           total_stake:"",
           my_rewards:"",
           internal_bonus:"12065",
           my_stake:"",
           my_nft:"0",
           myNfts:false,
           created_at:"1616516851",
           nft_name:"Crypto Mandala",
           nft_icon: DOMAIN_NAME+"/assets/images/projects/mandala/logo.svg",
           reward_address:"0xd88CcD0B00C887E1f9fFC4A56e3aeCC5896CD499",
           reward_decimal:"6",
           reward_icon:DOMAIN_NAME+"/assets/images/projects/mandala/reward_icon.svg",
           reward_symbol:"MNDL",
           pool_id: 1,
           pool_nft_addr:"0x28aed794742a574670a1ead16d90e056fb96aa9c",
           vault_addr: "0x607c452263A1f07cD2db97f4326124903e2f7787",
           off_chain_id:"0x28aed794742a574670a1ead16d90e056fb96aa9c",
           reward_claim_addr: "0x481aba6fc47a6b1d718e6e59a6a0708347944115",
           status:true
         }
       ],
       farms:[ ],
       tokens:[],
       nfts:[],
       status:true
    },
    polygon_dev:{
      vaults:[],
      farms:[],
      tokens:[],
      nfts:[],
      status:false
    }
  },
  status:true
}

export default Mandala;
