import React from 'react'
import VaultsCard from '../Vaults/VaultsCard';
import NoTransaction from "../Transaction/NoTransaction"

const VaultsSection = (props) => {
  const {vaults,project} = props;

  var VaultCards = vaults.map((vaultObj,i) => {
    return (
      <div className="col-lg-3" key={i}>
        <VaultsCard vault={vaultObj}  />
      </div>
    );
  })


  return (
    <>
       <section className="project-title" style={{ background:project.bottom_bg}} >
        <div className="container">
          <div className="row">
          
            {vaults.length < 1  ?
          
              <NoTransaction msg_txt = "No vaults are currently available  :( " />
            : 
            
            VaultCards
            }



            {/* {VaultCards} */}
          </div>
        </div>
      </section>
    </>
  )
}

export default VaultsSection
