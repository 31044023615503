import ReactJSON,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


import px from "../../assets/images/token_icons/px.svg"
import { CustomButton } from '../UIElements/Buttons'
import Toast from "./../Toast";

import { getFarmApproval, getFarmInfo } from "../../services/actions"
import { stakeTokens, etherUrl } from "../../utils/ethereum";

const BigNumber = require('bignumber.js');


const Stake = (props) => {
  const { farm, farms, user } = props;
  const [approveProcess, SetApproveProcess] = useState(false);
 
  const [tostInfo, setTostInfo] = useState({ type: "", title: "", message: "" });
  const [approveAmount, setApproveAmount] = useState("");
  const [stakingFlag, setStakingFlag] = useState(false);

  const network_key = user.network.key;
  const farmObj = farms[network_key].find(v => v.key == farm.key);


  const stakeTokenBtn = () => {
    setStakingFlag(true);

    let b_amount = new BigNumber(approveAmount);
    let tmp_amount = b_amount.toString();
    b_amount = b_amount.multipliedBy(new BigNumber(10**farmObj.token_decimal))
    let amount = b_amount.toFixed();

    stakeTokens(amount, farm, user.data.eth_address, (data) => {
      setStakingFlag(false);
      if (data.type == "success") {
        setApproveAmount("");
        props.getFarmInfo(farm, user);
        setTostInfo({ type: "success", title: "Staking Success", message: `${tmp_amount} Tokens staked successfully. <a href="${etherUrl(user.network, '/tx/' + data.data.transactionHash)}" target="_blank"> Click Here</a>` });
      } else {
        setTostInfo({ type: "error", title: "Staking Failed", message: data.message });
      }
    })
  }

  const approveStake = () => {
    SetApproveProcess(true);
    let b_amount = new BigNumber(approveAmount);
    b_amount = b_amount.multipliedBy(new BigNumber(10**farmObj.token_decimal))
    let amount = b_amount.toFixed();
    console.log("allowed",amount);
    console.log("approveAmount",approveAmount);

    props.getFarmApproval(farm,amount,user, (data) => {
      if (data.type == "error") {
        setTostInfo({ type: "error", title: "Error!", message: data.message });
      }
      SetApproveProcess(false);
    });
  }


  let b_amount = new BigNumber(farmObj.allowance);
  b_amount = b_amount.dividedBy(new BigNumber(10**farmObj.token_decimal))
  let allowed = b_amount.toFixed();
  // let allowed = farmObj.allowance/(10**farmObj.token_decimal)

  return (
    <>
      <div className="tab_first p-4">

        <div className={farm.my_tokens == 0 ? "set_opacity": "stake_area"}>
          <div className="stack_unstack_input_area">


            <div className="input-group d-flex align-items-center">
              <img src={farmObj.default_eth_icon ? farmObj.default_eth_icon : "/assets/images/tokens/eth-icon.png"} className="px-2 " width="40" />
              <input type="number" className="form-control border-0 p-2 my-2 stake_unstake_text_box" placeholder="0 Tokens" aria-label="Number Of NFTs" aria-describedby="basic-addon2" value={approveAmount} onChange={e=>setApproveAmount(e.target.value)} /> 
              <h6 className="px-3 mt-2 st_type"> {farmObj.t1_name}</h6>
              <button className="input-group-text" id="basic-addon2" onClick={()=>{setApproveAmount(farmObj.my_tokens_all)}}>MAX</button>
            </div>
          </div>

          <h5 className="py-4  stakeabl_text"><u>({farmObj.my_tokens} {farmObj.t1_name}) STAKEABLE</u></h5>

        </div>


        {(farmObj.my_tokens_all == 0 || approveAmount > farmObj.my_tokens_all)?
        <CustomButton className=" accent_btn set_opacity my-2  font-24" disabled={true}>Stake</CustomButton>
        :
          <>
          {(parseFloat(approveAmount) > (parseFloat(allowed))) ?
            <>
             <CustomButton className={approveProcess ? "accent_btn set_opacity my-2 font-24" : "accent_btn font-24 my-2"} disabled={approveProcess} onClick={approveStake}> {approveProcess ? "Approving TOKENS..." : "Approve tokens to stake"}</CustomButton>
             <h6 className="info_text my-3"> You will need to sign an approval transaction to stake. </h6>
             </>
           :
           <CustomButton className={(stakingFlag || (parseFloat(approveAmount)<=0 || approveAmount =="")) ? "eneable_btn  text-white my-2 set_opacity" : "eneable_btn  text-white my-2"} disabled={(stakingFlag || (parseFloat(approveAmount)<=0 || approveAmount ==""))} onClick={stakeTokenBtn}> <span className={stakingFlag? "set_opacity" :""}>{stakingFlag? "STAKING Tokens..." :"STAKE TOKENS"} </span></CustomButton>
          }
        </>
       }
      </div>

      {tostInfo.message != "" &&
        <Toast type={tostInfo.type} title={tostInfo.title} message={tostInfo.message} close={() => { setTostInfo({ type: "", title: "", message: "" }) }} />
      }

    </>
  )
}



Stake.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getFarmApproval: PropTypes.func,
  getFarmInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  farms: state.farms,
})

const mapDispatchToProps = {
  getFarmApproval,
  getFarmInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(Stake);