import React from 'react'
import px from "../../assets/images/token_icons/px.svg"


const Position = (props) => { 
  const {farm} = props;
  return (
    <>

      <div className="card w-100 no_shadow_card position_card my-5">
        <div className="card-header py-3  bg-transparent d-flex  justify-content-between align-items-center">
          <h1 className="position_heading">Your Current Position</h1>
          {/* <span className="badge position_badge ">You haven’t entered this vault yet</span> */}
        </div>
        <div className="card-body ">
          <div className="row position_count">
            <div className="text-center col-lg-4 col-md-6 col-sm-12 ">
              <label >Your Rewards</label>
              <h6><img src={farm.reward_icon} width="30" /> {farm.my_rewards} </h6>


            </div>
            <div className="text-center col-lg-4 col-md-6 col-sm-12">
              <label >Staked Tokens</label>
              <h6>  {farm.my_stake} </h6>

            </div>

            <div className="text-center col-lg-4 col-md-6 col-sm-12">
              <label >Rewards SHare</label>
              <h6> {Math.round((farm.my_stake/farm.total_stake)*100)}%  </h6>

            </div>
          </div>
        </div>

      </div>


    </>
  )
}

export default Position
