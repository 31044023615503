import React from 'react'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const CustomToolTip = (props) => {
  
  return (
    <>
      <OverlayTrigger
  
        className="tooltip-inner"
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip style={{marginTop:"10px"}}>{props.tooltip_msg}</Tooltip>}
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
              boundariesElement: 'viewport' 
            }
          }
        }}
        // trigger="hover"
      >
          {props.children}
      </OverlayTrigger>
    </>
  )
}

export default CustomToolTip
