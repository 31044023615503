import React,{useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
const ClaimRewardModal = (props) => {
  const [flag,setFlag] = useState(false);
  const {vault,user,handleClose,getAllRewards} = props;

  const unstakeAllRewards=()=>{
    getAllRewards(flag);
  }
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} backdrop={true} onHide={props.handleClose} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-550">

        <Modal.Body>

          <div className="container pb-2">
            <div className="row">
              <div className="col-12 model_heading mt-3">

                <h1 className="font-24 "> UNSTAKE TO CLAIM REWARDS</h1>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-lg-12">
                <div className="claim_balance erc_claim my-2">
                  <h5> EARNED REWARDS</h5>
                  <h3> {vault.my_rewards} {vault.reward_symbol}</h3>

                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 my-3 ">
                <div className="card_footer_1"></div>
              </div>
              <div className="col-lg-12 text-center claim_model_desc">
                <h6> Heads up!</h6>
                <p>To claim rewards, all your staked NFTs will be unstaked. <br/> Restake NFTs to earn additional rewards.</p>
              </div>
              <div className="col-lg-9 my-3 ">
                <div className="card_footer_1"></div>
              </div>
              <div className="col-lg-12 text-center d-flex justify-content-center">
                <h6 className="d-flex align-items-center"> <input type="checkbox" className="me-2" checked={flag} onChange={()=>{setFlag(!flag)}} id="do_not_show"/> <label htmlFor="do_not_show">GOT IT, DON'T SHOW THIS AGAIN </label></h6>
              </div>

              <div className="col-lg-12 text-center">
                <button className="eneable_btn  font-18 my-3" onClick={unstakeAllRewards}>
                  UNSTAKE AND CLAIM REWARDS
                </button>
                <button className="white_btn font-18  my-3" onClick={handleClose}>
                  Cancel
                </button>
              </div>

            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

ClaimRewardModal.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user : state.user,
  vaults : state.vaults,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimRewardModal);