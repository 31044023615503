import React from 'react'
import chainObj from "../../../services/constants/chains"

import cross from "../../../assets/images/close.png"

import Modal from 'react-bootstrap/Modal'
const NetworkError = (props) => {
  return (
    <>
      <Modal show={true} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-550">
        <Modal.Body>
          <div className="container pb-2">
            <div className="row">
              <div className="col-12 model_heading mt-3 d-flex justify-content-between">
                <h1 className="font-24 w-100 text-center"> Please Change Network</h1> 
                <img src={cross} onClick={props.close}  style={{width:"40px",height:"40px",marginTop:"-15px"}} />
              </div>
            </div>
    

            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 my-3 ">
                <div className="card_footer_1"></div>
              </div>
              <div className="col-lg-12 text-center claim_model_desc">
                <h6> Network Error!</h6>
                <p>You are connected to the wrong network. Please go to your wallet settings, select the {chainObj.map((v)=>v.name).join(", ")} network, and refresh this page.</p>
              </div>
              <div className="col-lg-9 my-3 ">
                <div className="card_footer_1"></div>
              </div>

            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default NetworkError
