import React,{useState,useRef,useEffect} from 'react'
import { connect } from 'react-redux'
import Hotkeys from 'react-hot-keys';

import {randomInteger} from "../../utils/index"
import {getNftImage} from "../../utils/ethereum"

import PropTypes from 'prop-types'

function LoadNft(props) {
  const {vaults,user} = props;
  const divEl = useRef(null);
  const [images,setImages] = useState([]);
  let nft_address =[];
  if(user.login){ 
    const network_key = user.network.key; 
   nft_address = vaults[network_key].map(v=>v.pool_nft_addr);
  }

  useEffect(()=>{
    let clickBody=()=>{
      setImages([]);
    }

    let disableTab = (t)=>{
        if(t.which == 9){
          t.preventDefault();
         return false;
        }
    }
    document.body.addEventListener('click', clickBody, true); 
    document.body.addEventListener('keydown', disableTab, true); 
    return (()=>{ 
      document.body.removeEventListener('click', clickBody);
      document.body.removeEventListener('keydown', disableTab);
    })

  },[])

  const loadNftImg =()=>{
    const network_key = user.network.key; 
    if(nft_address.length == 0 || !user.login ){
      return
    }
    let address =  nft_address[Math.floor(Math.random()*nft_address.length)];

    if(window.location.pathname.includes('/vault/')  && window.location.pathname.includes('/detail')){
      let vault_key = window.location.pathname.split("/");
      let v = vaults[network_key].find(v=>v.key == vault_key[2]);
      address = v.pool_nft_addr;
    }

    if(window.location.pathname.includes('/project/') && window.location.pathname.includes('/detail')){
      let project_key = window.location.pathname.split("/");
      let v = vaults[network_key].filter(v=>v.p_key == project_key[2]);
      nft_address = v.map(v=>v.pool_nft_addr);
      address = nft_address[Math.floor(Math.random()*nft_address.length)];;
    }

    getNftImage(address,(img_url,tokenId)=>{
      let img = [...images];
      img.push({src:img_url,top:randomInteger(0,(window.innerHeight-200)),left:randomInteger(0,(window.innerWidth-300)),rotate:randomInteger(-30,30)})
      setImages(img);
    })
    
  }

  return (
    <Hotkeys 
    keyName="tab+b" 
    onKeyDown={loadNftImg}
    onKeyUp={()=>{}}
  >
    <div id="load_images" ref={divEl}  >
      {images.map((v,i)=>{
        return <img key={i} src={v.src} style={{position:"absolute",top:v.top,left:v.left,transform: `rotate(${v.rotate}deg)`}} width="300"/>
      })}
    </div>
    </Hotkeys>
  )
}

LoadNft.propTypes = {

}

const mapStateToProps = (state) => ({
  user: state.user,
  vaults: state.vaults,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadNft);


