import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import VaultsSection from '../../component/Projects/VaultsSection'
import FarmsSection from '../../component/Projects/FarmsSection'

import discord from '../../assets/images/icons/discord.svg'
import globe from '../../assets/images/icons/globe.svg'
import telegram from '../../assets/images/icons/telegram.svg'
import twitter from '../../assets/images/icons/twitter.svg'
import linkIcon from '../../assets/images/icons/link.svg'



const ProjectDetail = (props) => {
  const projectKey = props.match.params.key;
  const { user, projects, vaults, farms } = props;
  const network_key = user.network.key;

  useEffect(() => {
    let fun = () => {
      props.history.push('/projects');
    }
    window.addEventListener('network_change', fun);
    return () => {
      window.removeEventListener('network_change', fun);
    }
  }, [])

  const project = projects[network_key].find(v => v.key == projectKey)
  const projectVaults = vaults[network_key].filter(v => v.p_key == project.key)
  const projectFarms = farms[network_key].filter(v => v.p_key == project.key)


  return (
    <>
      <div className="project-title" style={{ background: project.top_bg }}>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="font-32 px-3 py-2 mb-0" style={{ fontFamily: "AeroBold" }}>{project.name} </h2>

            </div>

          </div>

        </div>
      </div>
      <section className="project_container" style={{ background: `url(${project.big_banner})` }}>
        <div className="project_img_overlay">

        </div>
      </section>


      <section className="project_data" style={{ background: project.top_bg }}>

        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 project_logo_section">
                <img src={project.logo_image} width="150" />
              </div>
              <div className="col-lg-7 pt-5 pb-2 project_data_section">
                <span className="my-5">About The Project</span>
                <p className="mt-3" dangerouslySetInnerHTML={{ __html: project.details }}></p>
              </div>
            </div>
            {(project.website != "" || project.lite_paper != "") &&
              <div className="row d-flex justify-content-center project_data_links">

                <div className="col-lg-8 my-2">
                  <div className="card_footer_1"></div>
                </div>
                <div className="clear-fix"></div>

                <div className="col-lg-8  mb-4">
                  <ul className="projects_social_links">
                    {project.website != "" &&
                      <li className="project_social_link">
                        <a href={project.website} target="_blank"><img width="20" className="me-2" src={globe} />Website</a>
                      </li>
                    }
                    {project.twitter != "" &&

                      <li className="project_social_link">
                        <a href={"https://twitter.com/" + project.twitter} target="_blank"><img width="20" className="me-2" src={twitter} />twitter</a>
                      </li>
                    }

                    {project.discord != "" &&
                      <li className="project_social_link">
                        <a href={"https://discord.gg/" + project.discord} target="_blank"><img width="20" className="me-2" src={discord} />discord</a>
                      </li>
                    }

                    {project.lite_paper != "" &&
                      <li className="project_social_link">
                        <a href={project.lite_paper} target="_blank"><img width="20" className="me-2" src={linkIcon} />Litepaper</a>
                      </li>
                    }

                    {project.telegram != "" &&

                      <li className="project_social_link">
                        <a href={"https://t.me/" + project.telegram} className="" target="_blank"><img width="20" className="me-2" src={telegram} />telegram</a>
                      </li>

                    }
                  </ul>

                </div>

                {/* 
              <div className="clear-fix"></div>

              {project.website != "" &&
                <div className="col-lg-2 d-grid">
                  <label></label>
                  <a href={project.website} target="_blank"><img width="20" className="me-2" src={globe} />WEbsite</a>
                </div>
              }
              {project.website != "" &&
                <div className="col-lg-2 d-grid">
                  <label></label>
                  <a href={"https://twitter.com/" + project.twitter} target="_blank"><img width="20" className="me-2" src={twitter} />twitter</a>
                </div>
              }
              {project.website != "" &&
                <div className="col-lg-2 d-grid">
                  <label></label>
                  <a href={"https://discord.gg/" + project.discord} target="_blank"><img width="20" className="me-2" src={discord} />discord</a>
                </div>
              }

              {project.telegram == "" &&
                <div className="col-lg-2 d-grid ">
                  <label className="ps-2 "></label>
                  <a href={"https://t.me/" + project.telegram} className="ps-2 " target="_blank"><img width="20" className="me-2" src={telegram} />telegram</a>
                </div>
              } */}
              </div>
            }

          </div>
        </div>

      </section>
      <section className="view_nav_section">

        <div className="project-title" style={{ background: project.bottom_bg }} >
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="font-24 px-3 py-2 mb-0">Vaults </h2>

              </div>

            </div>

          </div>
        </div>
      </section>

      <VaultsSection vaults={projectVaults} project={project} />


      <section className="view_nav_section ">

        <div className="project-title" style={{ background: project.bottom_bg }} >
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 className="font-24 px-3 py-2 mb-0">Farms </h2>

              </div>

            </div>

          </div>
        </div>
      </section>

      <FarmsSection farms={projectFarms} project={project} />
    </>
  )
}


ProjectDetail.propTypes = {
  user: PropTypes.object,
  projects: PropTypes.object,
  vaults: PropTypes.object,
  farms: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user: state.user,
  projects: state.projects,
  vaults: state.vaults,
  farms: state.farms,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);