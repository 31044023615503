import React from 'react'

const Footer = () => {
  return (
    <>
      <section className="footer py-3">
        <footer>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12  d-flex justify-content-center">
                <ul className="footer_links">
                  <li className="footer-link-item">
                    <a className="footer-link-path" href="https://www.polyientx.com" target="_blank">ABOUT</a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link-path" href="http://discord.gg/fXgVxdp" target="_blank">COMMUNITY</a>
                  </li>
                  <li className="footer-link-item">
                    <a className="footer-link-path" href="https://docs.polyientx.com" target="_blank">DEVELOPERS</a>
                  </li>
                 
                </ul>
              </div>

            </div>

            <div className="row d-flex justify-content-center py-3">
              <div className="col-12 text-center"><h5 style={{textTransform:"none"}}>&copy; PolyientX - 2022</h5></div>
            </div>
          </div>
        </footer>
      </section>

    </>
  )
}

export default Footer
