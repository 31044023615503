import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ErcTransaction from '../../component/Transaction/ErcTransaction'
import ClaimErc from '../../component/ERC721/ClaimErc'
import ErcPosition from '../../component/ERC721/ErcPosition'
import ErcTokenDetail from '../../component/ERC721/ErcTokenDetail'

import { getVaultInfo } from "../../services/actions"
import { addAlpha } from "../../utils/index"


const VaultDetails = (props) => {
  const vaultKey = props.match.params.key;
  const { user, vaults } = props;
  const network_key = user.network.key;

  const vault = vaults[network_key].find(v => v.key == vaultKey);


  useEffect(() => {
    let interval = setInterval(() => {
      props.getVaultInfo(vault, user);
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, [])

  useEffect(() => {
    let fun = () => {
      props.history.push('/projects');
    }
    window.addEventListener('network_change', fun);
    return () => {
      window.removeEventListener('network_change', fun);
    }
  }, [])

  return (
    <>
      <section className="card_area_2 test py-5" style={{ background: `linear-gradient(180deg, rgb(255 255 255 / 34%) 51.02%, ${addAlpha(vault.bg_color, 0.1)} 56.86%, ${addAlpha(vault.bg_color, 0.2)} 62.7%, ${addAlpha(vault.bg_color, 0.2)} 68.53%, ${addAlpha(vault.bg_color, 0.3)} 74.37%, ${addAlpha(vault.bg_color, 0.3)} 80.21%, ${addAlpha(vault.bg_color, 0.3)} 80.21%, ${addAlpha(vault.bg_color, 0.3)} 84.17%, ${addAlpha(vault.bg_color, 0.3)} 88.13%, ${addAlpha(vault.other_color, 0.2)} 92.08%, ${addAlpha(vault.other_color, 0.2)} 96.04%, ${addAlpha(vault.other_color, 0.5)} 100%)` }}>
        <div className="container ">
          <div className="row ">
            <div className="col-lg-4 col-md-6 col-sm-12 ">
              <ErcTokenDetail vault={vault} user={user} />
            </div>
            <div className="col-lg-8">
              <ClaimErc vault={vault} underline="true" />
              {vault.my_stake > 0 ?
                <ErcPosition vault={vault} />
                :
                <br />
              }
              <ErcTransaction vault={vault} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


VaultDetails.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  vaults: state.vaults,
})

const mapDispatchToProps = {
  getVaultInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(VaultDetails);
