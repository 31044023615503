import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Tab, Nav } from 'react-bootstrap'
import img_3 from '../../assets/images/img_3.png'
import share from '../../assets/images/share.png'

import NoTransaction from "./NoTransaction"
import TransactionData from './TransactionData'

import {getContractEventLogs} from "../../utils/ethereum"

const Transaction = (props) => { 
  const {farm,user} = props;

  const [txHistory,setTxHistory] = useState([]);
  const [myTxHistory,setMyTxHistory] = useState([]);
  const [txLoaded,setTxLoaded] = useState(false);

  useEffect(()=>{
    setTxLoaded(false);
    getContractEventLogs(farm,user.data.eth_address,(data)=>{
      // setTxHistory(data.data);
       let alldata =[];
      let mydata =[];
          data.data.map((v,i)=>{
          
          if(v.event =="Staked" || v.event=="Unstaked"){ 
                alldata.push(v);   
              if(v.returnValues.user.toLocaleLowerCase() == user.data.eth_address.toLocaleLowerCase()){
                mydata.push(v);
              }   
            }  
            
          //    let flag = true;
          // v.txObject.logs.map((log)=>{
          //     if(log.address.toLocaleLowerCase() == farm.pool_token_addr.toLocaleLowerCase() && flag == true){
          //       flag = false;
          //         alldata.push(v);
          //         if(v.txObject.from.toLocaleLowerCase() == user.data.eth_address.toLocaleLowerCase()){
          //           mydata.push(v);
          //         } 
          //     }
          // })
      })
      setTxHistory(alldata);
      setMyTxHistory(mydata);
      setTxLoaded(true);
    })
  },[])
  


  return (
    <>
      <div className="card w-100 no_shadow_card  transaction_table_section p-0">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav className="d-flex bd-highlight  tab_section d-flex align-items-center">
            <Nav.Item className=" bd-highlight tb_option transaction_tab w-50 text-center">
              <Nav.Link eventKey="first">Farm Transactions</Nav.Link>
            </Nav.Item>
            <Nav.Item className=" bd-highlight tb_option transaction_tab w-50 text-center border-end-0">
             <Nav.Link eventKey="second">Your Transactions</Nav.Link>
            </Nav.Item>

          </Nav>


          <div className="card-body p-0 ">
            <Tab.Content className="">
              <Tab.Pane eventKey="first">
                <div className="transaction_tab_1 p-3">
                {txLoaded ?
                <>
                  {txHistory.length == 0 ? 
                    <NoTransaction  msg_txt = "This farm has no recent transactions :(" />
                  :
                    <TransactionData vault={farm} history={txHistory} user={user} />
                  }
                  </>
                  :
                  <>
                    <h3 className="text-center mt-5 mb-10"> Loading...</h3>
                    <br/>
                    <br/>
                  </>
                }

                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second" >
                <div className="transaction_tab_1 p-3">
                  {txLoaded ?
                  <>
                    {myTxHistory.length == 0 ? 
                      <NoTransaction  msg_txt = "This farm has no recent transactions :(" />
                    :
                      <TransactionData vault={farm} history={myTxHistory} user={user} />
                    }
                    </>
                    :
                    <>
                      <h3 className="text-center mt-5 mb-10"> Loading...</h3>
                      <br/>
                      <br/>
                    </>
                  }
                </div>
              </Tab.Pane>
            </Tab.Content>

          </div>
        </Tab.Container>
      </div>

    </>
  )
}


Transaction.propTypes = {
  user: PropTypes.object,
  farms: PropTypes.object,
  getVaultInfo: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user : state.user,
  farms : state.farms,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);