import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
import Toast from "../../Toast";


import select from '../../../assets/images/select.png'
import unselect from '../../../assets/images/unselect.png'

import { getVaultMyNFTS } from "../../../services/actions"

const StackNFTModal = (props) => {
  const { vault, user, selectNft, setSelectNft, handleClose } = props;
  const [error, setError] = useState("");


  const chooseNft = (id) => {
    const nftIds = [...selectNft];
    if (nftIds.includes(id)) {
      nftIds.splice(nftIds.indexOf(id), 1);
    } else {
      nftIds.push(id);
    }
    setSelectNft(nftIds);
  }


  useEffect(()=>{
    props.getVaultMyNFTS(vault,user,(data)=>{
      if(data.type=="error"){ 
        setError(data.message);
      }
    })
  }, [])


  if(typeof vault.stakedTokenIds =='undefined'){
    vault.stakedTokenIds=[];
  }

  let nftHtml = [];
  console.log("vault.myNfts",vault.myNfts);
  if (vault.myNfts !== false && typeof vault.myNfts!='undefined') {
    nftHtml = vault.myNfts.map((v, i) => {
      if(vault.stakedTokenIds.includes(parseInt(v.id))){
        return null
      }
      return (<div className="col-lg-4 mb-3" key={i}>
        <div className="card nft_card" onClick={() => { chooseNft(v.id) }}>
          <div className="card-body ">
            <label className="d-flex align-items-center nft_chk">
              <img src={selectNft.includes(v.id) ? select : unselect} className="me-1" /> Select</label>
            <div className="nft_area" style={{ "background": `url(${v.image || v.image_url})`, "backgroundSize": "contain", "backgroundRepeat": "no-repeat" }}></div>
            <div className="ps-2">
              <span className="nft_type">ID / Contract / Type</span>
              <h2 className="nft_name">{v.name} (#{v.id})</h2>
            </div>
          </div>
        </div>
      </div>
      );
    })
  }


  return (
    <>
      <Modal size="xl" show={props.show} onHide={props.handleClose} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-90w">

        <Modal.Body>

          <div className="container pb-2">
            <div className="row">
              <div className="col-12 model_heading">
                <h1 className="font-24 "> CHOOSE NFTS ({selectNft.length})</h1>
              </div>
              {/* <div className="col-3 model_heading">   <button className="eneable_btn py-3 my-1" disabled={selectNft.length == 0} onClick={() => { handleClose(true) }}>USE All({selectNft.length})</button> </div> */}
            </div>
            <div className="row my-3 nft_selection_area">
              
              {vault.myNfts == false ?
                <>
                  <h1 className="text-center mt-5">Loading...</h1>
                </>
                :
                <>
                  {nftHtml.length == 0 ? <h1 className="text-center mt-5">No NFT found.</h1> : nftHtml}
                </>
              }

            </div>

            <div className="row nft_action">
              <div className="col-md-6 col-sm-12">

                <button className=" white_btn py-3 my-2" onClick={handleClose}>Cancel</button>
              </div>
              <div className="col-md-6 col-sm-12">
                <button className="eneable_btn py-3 my-1" disabled={selectNft.length == 0} onClick={() => { handleClose(true) }}>USE ({selectNft.length})</button>
              </div>
            </div>

          </div>
        </Modal.Body>
      </Modal>
      {error != "" &&
        <Toast type="error" title="Error!" message={error} close={() => { setError("") }} />
      }
    </>
  )
}





StackNFTModal.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
  getVaultMyNFTS: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  vaults: state.vaults,
})

const mapDispatchToProps = {
  getVaultMyNFTS
}

export default connect(mapStateToProps, mapDispatchToProps)(StackNFTModal);