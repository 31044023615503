import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import {ETH_ENV,DOMAIN_NAME,OFF_CHAIN_SERVER,REWARD_CONTRACT_ADDR} from '../services/constants'
import ERC20 from '../services/abi/ERC20'
import ERC721 from '../services/abi/ERC721'
import ERC721STAKING from '../services/abi/ERC721STAKING'
import ERC20STAKING from '../services/abi/ERC20STAKING'
import RewardsClaimer from '../services/abi/RewardsClaimer'
import chainObject,{isChainAllow,getNetwork} from "../services/constants/chains"


import {randomInteger} from "../utils/index";

const BigNumber = require('bignumber.js');


const ethereum = window.ethereum;

// for ethereum interaction function
export const web3 = new Web3();

export async function connect(dispatch){
  if (typeof window.ethereum === 'undefined') {
    window.open("https://metamask.app.link/dapp/"+DOMAIN_NAME.replace("https://", "").replace("http://", "")+"/dashboard?login=metamask");
    return
  } 
  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });

    // ethereum.on('chainChanged', function (accounts) {
    //   window.location.reload();
    // });

    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(provider=>{
          web3.setProvider(provider);
          web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            network['networkVersion'] = await ethereum.request({ method: 'net_version' });
            if(isChainAllow(network['chainId'])){
              dispatch({type:"USER_SUCCESS",data:{network:getNetwork(network['chainId']),data:{eth_address:ethereum.selectedAddress},login:true}});
            }else{
              dispatch({type:"USER_NETWORK_ERROR",data:{network:false,data:{eth_address:ethereum.selectedAddress},login:false}});
            }
            networkChangeEvent(dispatch);
          })
        });
    }else{
      dispatch({type:"USER_ERROR",message: "Some error occurred!"});
    } 
  }).catch((error)=>{
    dispatch({type:"USER_ERROR",message: error.message});
  });
}

export async function networkChangeEvent(dispatch){

        // link chain
      ethereum.on('chainChanged',  async(accounts)=> {
        web3.eth.net.getNetworkType().then(async(network_type) => {
          // set provider
          detectEthereumProvider().then(provider=>{
            web3.setProvider(provider);
          });

          let network = {}
          network['type'] = network_type;
          network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
          network['networkVersion'] = await ethereum.request({ method: 'net_version' });

          if(isChainAllow(network['chainId'])){

            var event = new Event('network_change');
            window.dispatchEvent(event);

            dispatch({type:"USER_NETWORK_CHANGE",data:{network:getNetwork(network['chainId']),data:{eth_address:ethereum.selectedAddress},login:true}});
          }else{
            dispatch({type:"USER_NETWORK_ERROR",data:{network:false,data:{eth_address:ethereum.selectedAddress},login:false}});
          }
         
        })
    });

}

export async function farmStakeBalance(farm,eth_address){
    const farmStakeContract = new web3.eth.Contract(ERC20STAKING, farm.vault_addr);
    let userStakeInfo = await farmStakeContract.methods.getUserInfoForPool(farm.pool_id,eth_address).call();
    return userStakeInfo.amount;
}


export async function farmInfo(farm,eth_address){
  const tokenContract = new web3.eth.Contract(ERC20, farm.pool_token_addr);
  const farmStakeContract = new web3.eth.Contract(ERC20STAKING, farm.vault_addr);
  window.farmStakeContract = farmStakeContract;

  // comment below if want to set fix
  farm.total_capacity = await tokenContract.methods.totalSupply().call();
  farm.total_capacity = farm.total_capacity /(10**farm.token_decimal);
  farm.my_tokens=await tokenContract.methods.balanceOf(eth_address).call();

  // console.log("await tokenContract.methods.balanceOf(eth_address).call()",await tokenContract.methods.balanceOf(eth_address).call());
  let b_amount1 = new BigNumber(farm.my_tokens);
  b_amount1 = b_amount1.dividedBy(new BigNumber(10**farm.token_decimal))
  farm.my_tokens_all = b_amount1.toFixed();

  // console.log("b_amount1",b_amount1.toFixed());

  farm.my_tokens = b_amount1.toFixed(18);
  if(farm.my_tokens.split(".")[1] == '000000000000000000'){
    farm.my_tokens = farm.my_tokens.split(".")[0];
  }else{
    farm.my_tokens = b_amount1.toFixed(5);
  }
  // farm.my_tokens = farm.my_tokens /(10**farm.token_decimal);
  farm.allowance = await tokenContract.methods.allowance(eth_address,farm.vault_addr).call();
  let stakeInfo = await farmStakeContract.methods.getPoolInfoFor(farm.pool_id).call();
  farm.total_stake = stakeInfo.totalDeposit;

  b_amount1 = new BigNumber(farm.total_stake);
  b_amount1 = b_amount1.dividedBy(new BigNumber(10**farm.token_decimal))
  farm.total_stake = b_amount1.toFixed(18);
  // farm.total_stake = farm.total_stake /(10**farm.token_decimal);
  if(farm.total_stake.split(".")[1] == '000000000000000000'){
    farm.total_stake = farm.total_stake.split(".")[0];
  }else{
    farm.total_stake = b_amount1.toFixed(5);
  }
  


  let userStakeInfo = await farmStakeContract.methods.getUserInfoForPool(farm.pool_id,eth_address).call();
  // farm.my_stake = userStakeInfo.amount/ (10**farm.reward_decimal);
  
  b_amount1 = new BigNumber(userStakeInfo.amount);
  b_amount1 = b_amount1.dividedBy(new BigNumber(10**farm.token_decimal))
  farm.my_stake = b_amount1.toFixed(18);
  farm.my_stake_actual = b_amount1.toFixed();

  if(farm.my_stake.split(".")[1] == '000000000000000000'){
    farm.my_stake = farm.my_stake.split(".")[0];
  }else{
    farm.my_stake = b_amount1.toFixed(5);
  }
  
  farm.my_rewards = userStakeInfo.rewardDebt;
  farm.my_rewards = await farmStakeContract.methods.getPendingTokens(farm.pool_id,eth_address).call();
  let b_amount = new BigNumber(farm.my_rewards);
  b_amount = b_amount.dividedBy(new BigNumber(10**farm.reward_decimal))
  farm.my_rewards = b_amount.toFixed(18);

  if(farm.my_rewards.split(".")[1] == '000000000000000000'){
    farm.my_rewards = farm.my_rewards.split(".")[0];
  }else{
    farm.my_rewards = b_amount.toFixed(5);
  }
  // if(farm.my_rewards.toString().split(".").length > 1 ){
  //   farm.my_rewards = farm.my_rewards.toFixed(15);
  // } 

    // calculation for total reward and bonus
  let poolsInfo = await farmStakeContract.methods.getPools().call();
  let totalPoints = 0;
  poolsInfo.allocPoints.map(v=>totalPoints+=parseInt(v));
  let allocPoints = parseInt(poolsInfo.allocPoints[parseInt(farm.pool_id)]);

  let infoData = await farmStakeContract.methods.getInfo().call();
  let outputPerBlockNumber = infoData.outputPerBlockNumber/(10**18);

  let outputPoolBlock = infoData.outputPerBlockNumber*(((allocPoints/totalPoints)*100)/100);
  outputPoolBlock = outputPoolBlock/(10**18);

  let multiplier = await farmStakeContract.methods.getMultiplier(infoData.startBlockNumber,stakeInfo.lastRewardBlock).call();
  let totalVaultPnt = multiplier*outputPoolBlock;

  let currentPntReward = totalVaultPnt/(farm.stakeTotal>0 ? farm.stakeTotal : 1);
  let minPntReward = totalVaultPnt/farm.total_stake;
  let bonus = 1+parseFloat(((currentPntReward-minPntReward)/minPntReward).toFixed(2));
  // farm.rewards_per_day = (outputPoolBlock * farm.internal_bonus * 6488)/(farm.stakeTotal>0 ? farm.stakeTotal : 1);

  // 24 Hours * 60 Minutes * 60 Seconds = 86,400
  // 86,400 / 2 Seconds Avalanche = 43,200
  // 86,400 / 12 SECONDS ETHEREUM =  7,200


  // farm.rewards_per_day = (outputPoolBlock * 6381)/(farm.total_stake>0 ? farm.total_stake : 1); - Old calculation 
  farm.rewards_per_day = (outputPoolBlock * farm.perBlock)/(farm.total_stake>0 ? farm.total_stake : 1);

  if(farm.rewards_per_day.toString().split(".").length > 1 ){
    farm.rewards_per_day = farm.rewards_per_day.toFixed(5);
  }

  farm.bonus = bonus
  if(farm.bonus.toString().split(".").length > 1 ){
    farm.bonus = farm.bonus.toFixed(0);
  }
  farm.bonus = farm.bonus+"X";
  return farm;
}

export async function approveFarm(farm,amount,eth_address){
  const nftContract = new web3.eth.Contract(ERC721, farm.pool_token_addr);
  await nftContract.methods.approve(farm.vault_addr,amount.toString()).send({from:eth_address});
}

export async function stakeTokens(amount,farm,eth_address,callback){
  const tokenContract = new web3.eth.Contract(ERC20, farm.pool_token_addr);
  const farmStakeContract = new web3.eth.Contract(ERC20STAKING, farm.vault_addr);
  window.farmStakeContract = farmStakeContract;
  try{
    let allowAmount = await tokenContract.methods.allowance(eth_address,farm.vault_addr).call();
  
    if(allowAmount >amount){
      amount = amount.toString();
      await tokenContract.methods.approve(farm.vault_addr,amount).send({from:eth_address});
    }
    amount = amount.toString();
    let stakeInfo = await farmStakeContract.methods.stake(farm.pool_id,amount).send({from:eth_address});
    let finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
    callback({type:"success",data:finalTx})
  }catch(error){
    callback({type:"error",message:error.message})
  }  
}

export async function unStakeTokens(farm,eth_address,callback){
  const farmStakeContract = new web3.eth.Contract(ERC20STAKING, farm.vault_addr);
  try{
    let info = await farmStakeContract.methods.getUserInfoForPool(farm.pool_id,eth_address).call();
    let stakeInfo = await farmStakeContract.methods.unstake(farm.pool_id,info.amount).send({from:eth_address});
    let finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
    callback({type:"success",data:finalTx})
  }catch(error){
    callback({type:"error",message:error.message})
  } 
}


export async function vaultStakeBalance(vault,eth_address){
    const vaultStakeContract = new web3.eth.Contract(ERC721STAKING, vault.vault_addr);
     let userStakeInfo = await vaultStakeContract.methods.getUserInfoForPool(vault.pool_id,eth_address).call();
    return userStakeInfo.tokenIDs.length;
}


export async function offChainInfo(vault,eth_address){
  eth_address = validateAddress(eth_address);
  let obj ={}
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json'}
  };
  let myReward,vaultInfo,stakedInfo,unclaimedRewards = {}
  // try{ 
  // let allReward = await fetch(OFF_CHAIN_SERVER+"rewards/by-vault/"+vault.vault_addr,requestOptions);
  // allReward = await allReward.json();
  // console.log("allReward",allReward);
  try{ 
  myReward = await fetch(OFF_CHAIN_SERVER+"rewards/by-vault-and-account/"+vault.off_chain_id+"/"+eth_address,requestOptions);
  myReward = await myReward.json();
  }catch(e){

  }

  // try{ 
    vaultInfo = await fetch(OFF_CHAIN_SERVER+"rewards/vault-by-id/"+vault.off_chain_id,requestOptions);
    vaultInfo = await vaultInfo.json();
  // }catch(e){

  // }
  
  try{ 
    stakedInfo = await fetch(OFF_CHAIN_SERVER+"rewards/staked/by-vault-and-account/"+vault.off_chain_id+"/"+eth_address,requestOptions);
    stakedInfo = await stakedInfo.json()
    stakedInfo =stakedInfo.stakeEvents.map(v=>{return parseInt(v.inputTokenId)});
  }catch(e){

  }

  try{ 
    unclaimedRewards = await fetch(OFF_CHAIN_SERVER+"rewards/unclaimed/"+vault.off_chain_id+"/"+eth_address,requestOptions);
    unclaimedRewards = await unclaimedRewards.json()
    unclaimedRewards = await unclaimedRewards.vaultEvents
  }catch(e){

  }


  return {my_rewards:myReward.earned,vaultInfo:vaultInfo,stakedInfo:stakedInfo,unclaimedRewards:unclaimedRewards}
  // }catch(e){
  //   return {allReward:"",myReward:""}
  // }

  

}

export async function vaultInfo(vault,eth_address){
  if(vault.off_chain){
    let vaultData = await offChainInfo(vault,eth_address);
    console.log("vaultData",vaultData);
    console.log("unclaimedRewards",vaultData.unclaimedRewards);
    console.log("stakedInfo",vaultData.stakedInfo);
    console.log("vaultData.vaultInfo.inputTokens[0]",vaultData.vaultInfo.inputTokens);
    console.log("vaultData.vaultInfo.outputTokens[0].outputTokenDecimals",vaultData.vaultInfo.outputTokens[0].outputTokenDecimals);

    // outputRewardRate * (60 * 60 * 24) / totalStaked

    vault.created_at = ((new Date(vaultData.vaultInfo.createdAt)).getTime() / 1000).toFixed(0);
    vault.total_stake = vaultData.vaultInfo.inputTokens[0].inputTotalSupply;

    vault.per_day = (vaultData.vaultInfo.outputTokens[0].outputRewardRate * (60 * 60 * 24))/vault.total_stake;
    vault.per_day = vault.per_day/(10 ** vaultData.vaultInfo.outputTokens[0].outputTokenDecimals);
    vault.per_day = vault.per_day.toFixed(4);
    console.log("vault.per_day",vault.per_day);

    let token_decimal = vaultData.vaultInfo.outputTokens[0].outputTokenDecimals;
    let b_amount = new BigNumber(Math.floor(vaultData.my_rewards));
    let tmp_amount = b_amount.toString();
    b_amount = b_amount.dividedBy(new BigNumber(10**token_decimal))
    let reward_amount = b_amount.toFixed();
    // vault.my_rewards = vaultData.my_rewards.toFixed(5);
    vault.my_rewards = reward_amount;
    vault.unclaimedRewards = vaultData.unclaimedRewards;
    if(vault.unclaimedRewards.length > 0){
      console.log("vault.unclaimedRewards",vault.unclaimedRewards);
      console.log("vault.unclaimedRewards",vault.unclaimedRewards[0]);
      let r_amount = new BigNumber((vault.unclaimedRewards[0].claimInfo.rewardsInfo[0].amount));
      r_amount = r_amount.dividedBy(new BigNumber(10**token_decimal))
      vault.unclaimedRewardAmount = r_amount.toFixed();
    }

    try{
    const nftContract = new web3.eth.Contract(ERC721, vault.pool_nft_addr);
    let totalNft = await nftContract.methods.balanceOf(eth_address).call();
    let leftStake = totalNft - vaultData.stakedInfo.length;
    vault.my_nft = leftStake > 0 ? leftStake : 0;
    vault.my_stake = vaultData.stakedInfo.length;
    vault.stakedTokenIds = vaultData.stakedInfo;
    }catch(e){

    }
    console.log("vault",vault);

  }else{
  const nftContract = new web3.eth.Contract(ERC721, vault.pool_nft_addr);
  const vaultStakeContract = new web3.eth.Contract(ERC721STAKING, vault.vault_addr);

  window.vaultStakeContract = vaultStakeContract;

  // comment below if want to set fix
  // vault.total_capacity = await nftContract.methods.totalSupply().call();
  vault.my_nft=await nftContract.methods.balanceOf(eth_address).call();
  vault.is_approved = await nftContract.methods.isApprovedForAll(eth_address,vault.vault_addr).call();
  let stakeInfo = await vaultStakeContract.methods.getPoolInfoFor(vault.pool_id).call();
  vault.total_stake = stakeInfo.totalDeposit;
  let userStakeInfo = await vaultStakeContract.methods.getUserInfoForPool(vault.pool_id,eth_address).call();
  vault.my_stake = userStakeInfo.tokenIDs.length;
  vault.my_rewards = userStakeInfo.rewardDebt;
  vault.my_rewards = await vaultStakeContract.methods.getPendingTokens(vault.pool_id,eth_address).call();

  vault.my_rewards = await vaultStakeContract.methods.getPendingTokens(vault.pool_id,eth_address).call();
  let b_amount = new BigNumber(vault.my_rewards);
  b_amount = b_amount.dividedBy(new BigNumber(10**vault.reward_decimal))
  vault.my_rewards = b_amount.toFixed(5);

  // vault.my_rewards = (vault.my_rewards/ (10**vault.reward_decimal));
  // if(vault.my_rewards.toString().split(".").length > 1 ){
  //   vault.my_rewards = vault.my_rewards.toFixed(15);
  // } 

    // calculation for total reward and bonus
  let poolsInfo = await vaultStakeContract.methods.getPools().call();
  let totalPoints = 0;
  poolsInfo.allocPoints.map(v=>totalPoints+=parseInt(v));
  let allocPoints = parseInt(poolsInfo.allocPoints[parseInt(vault.pool_id)]);

  let infoData = await vaultStakeContract.methods.getInfo().call();
  let outputPerBlockNumber = infoData.outputPerBlockNumber/(10**18);
  let outputPoolBlock = infoData.outputPerBlockNumber*(((allocPoints/totalPoints)*100)/100);
  outputPoolBlock = outputPoolBlock/(10**18);

  let multiplier = await vaultStakeContract.methods.getMultiplier(infoData.startBlockNumber,stakeInfo.lastRewardBlock).call();
  let totalVaultPnt = multiplier*outputPoolBlock;

  let currentPntReward = totalVaultPnt/(vault.stakeTotal>0 ? vault.stakeTotal : 1);
  let minPntReward = totalVaultPnt/vault.total_stake;
  let bonus = 1+parseFloat(((currentPntReward-minPntReward)/minPntReward).toFixed(2));
  // vault.rewards_per_day = (outputPoolBlock * vault.internal_bonus * 6488)/(vault.stakeTotal>0 ? vault.stakeTotal : 1);
  // 24 Hours * 60 Minutes * 60 Seconds = 86,400
  // 86,400 / 2 Seconds Avalanche = 43,200
  // 86,400 / 12 SECONDS ETHEREUM =  7,200

  // vault.rewards_per_day = (outputPoolBlock * 6381)/(vault.total_stake>0 ? vault.total_stake : 1); - OLD CALCULATION 

  vault.rewards_per_day = (outputPoolBlock * vault.perBlock)/(vault.total_stake>0 ? vault.total_stake : 1);
  if(vault.rewards_per_day.toString().split(".").length > 1 ){
    vault.rewards_per_day = vault.rewards_per_day.toFixed(5);
  }

  vault.bonus = bonus
  if(vault.bonus.toString().split(".").length > 1 ){
    vault.bonus = vault.bonus.toFixed(0);
  }
  vault.bonus = vault.bonus+"X";
  
  }

  return vault;
}

export async function approveVault(vault,eth_address){
  const nftContract = new web3.eth.Contract(ERC721, vault.pool_nft_addr);
  await nftContract.methods.setApprovalForAll(vault.vault_addr,true).send({from:eth_address});
}

function addressEqual(to,from){
  return to.toString().toLowerCase() == from.toString().toLowerCase();
}

async function getOwnedTokens(contract,account) {
  const token = contract.methods;
  let sentLogs = await contract.getPastEvents("Transfer",{
    filter: {from: account},
    fromBlock: 0,
    toBlock: 'latest'
   })
   let receivedLogs = await contract.getPastEvents("Transfer",{
    filter: {to: account},
    fromBlock: 0,
    toBlock: 'latest'
   })
  const logs = sentLogs.concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber ||
        a.transactionIndex - b.TransactionIndex,
    );

  let owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.returnValues;
    if (addressEqual(to, account)) {
      owned.add(tokenId.toString());
    } else if (addressEqual(from, account)) {
      owned.delete(tokenId.toString());
    }
  }
  owned = [...owned];
  return owned;

};

export async function getNftImage(nft_addr,callback){
  const nftContract = new web3.eth.Contract(ERC721, nft_addr);
  const totalSupply =  await nftContract.methods.totalSupply().call();
  let number = randomInteger(1,totalSupply-1);
  let tokenId = await nftContract.methods.tokenByIndex(number).call();
  let tokenUri = await nftContract.methods.tokenURI(tokenId).call();
  let tokenData;
  let image = null;
  try{
    tokenData = await fetch(tokenUri).then(response => response.json());
    image = tokenData.image;
  }catch(e){
    tokenUri = `https://api.opensea.io/api/v1/asset/${nft_addr}/${tokenId}`;
    // will set X-API-KEY in header
    tokenData = await fetch(tokenUri).then(response => response.json());
    image = tokenData.image_url
  }
  callback(image,tokenId);
}

export async function loadNfts(vault,eth_address){
  const nftContract = new web3.eth.Contract(ERC721, vault.pool_nft_addr);
    const userTokenCount = await nftContract.methods.balanceOf(eth_address).call();
 
    let tokenIds =[];
    try{
      for(let i=0;i<userTokenCount;i++){
        let tokenId = await nftContract.methods.tokenOfOwnerByIndex(eth_address,i).call();

        if(vault.off_chain && vault.stakedTokenIds.includes(parseInt(tokenId))){
          continue;
        }else{
          tokenIds.push(tokenId);
        }
       
      } 
    }catch(e){
      // read token ids from other function
      tokenIds = await getOwnedTokens(nftContract,eth_address);
    }
   
    tokenIds.sort();
    tokenIds.reverse();

    // tokenIds =[]
    // for(let i=0;i<20;i++){
    //   tokenIds.push(i+1);
    // }

    let totalIds = tokenIds.length;
    if(vault.off_chain){
      totalIds = tokenIds.length > 30 ? 30 : tokenIds.length;
    }

    let tokens =[];
    for(let i=0;i<totalIds;i++){
      let tokenUri = await nftContract.methods.tokenURI(tokenIds[i]).call();
      // tokenUri="https://cryptomandala.art/api/mandala/"+tokenIds[i];
      // 
      try{
        let tokenData;
        // handle token read from base URL if not then try to use open sea url
        try{
           tokenData = await fetch(tokenUri).then(response => response.json());
           tokenData.id = tokenIds[i];
        }catch(e){
          tokenUri = `https://api.opensea.io/api/v1/asset/${vault.pool_nft_addr}/${tokenIds[i]}`;
          // will set X-API-KEY in header
          tokenData = await fetch(tokenUri).then(response => response.json());
        }
        if(typeof tokenData.name =='undefined' ){
          tokenData.name ='N/A'
        }

        if(typeof tokenData.image =='undefined' ){
          tokens.push({id:tokenIds[i],name:"N/A"});
        }else{
          tokens.push(tokenData);
        }
        
      }catch(e){
        tokens.push({id:tokenIds[i],name:"N/A"});
      }
    }
    return tokens;
}

export async function stakeNfts(tokenIds,vault,eth_address,callback){
   const nftContract = new web3.eth.Contract(ERC721, vault.pool_nft_addr);
   const vaultStakeContract = new web3.eth.Contract(ERC721STAKING, vault.vault_addr);
    try{
      let flag = await nftContract.methods.isApprovedForAll(eth_address,vault.vault_addr).call();
      if(!flag){
        await nftContract.methods.setApprovalForAll(vault.vault_addr,true).send({from:eth_address});
      }
      let stakeInfo = await vaultStakeContract.methods.stakeAll(vault.pool_id,tokenIds).send({from:eth_address});
      let finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
      callback({type:"success",data:finalTx})
    }catch(error){
      callback({type:"error",message:error.message})
    }  
}


export async function claimPastRewards(vault,eth_address,callback){
  try{ 
    eth_address = validateAddress(eth_address);
    const vaultStakeContract = new web3.eth.Contract(RewardsClaimer, vault.reward_claim_addr);
    console.log("vault.vault",vault);
    console.log("vault.unclaimedRewards",vault.unclaimedRewards);
   
    let myReward = vault.unclaimedRewards[0]
    myReward.claimInfo.rewardsInfo[0].amount =myReward.claimInfo.rewardsInfo[0].amount.toString();
    console.log("myReward.claimInfo",myReward.claimInfo);
    console.log("myReward.signature",myReward.claimSignature);
    console.log("myReward.eth_address",eth_address);
    // myReward.claimInfo.vaultId="xx";
  let claimInfo = await vaultStakeContract.methods.claim(myReward.claimInfo,myReward.claimSignature).send({from:eth_address});
  let finalTx = await web3.eth.getTransactionReceipt(claimInfo.transactionHash);
  callback({type:"success",data:finalTx})
}catch(error){
  callback({type:"error",message:error.message})
} 
 
  
}

export async function syncOffChainVaults(chain_id,tx_hash){
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json'}
  };
  
  let syncVaults = await fetch(OFF_CHAIN_SERVER+"rewards/sync/"+chain_id+"/"+tx_hash,requestOptions);
  syncVaults = await syncVaults.json();
  return syncVaults;
 
}

export async function claimRewards(vault,eth_address,callback){
  try{ 
    eth_address = validateAddress(eth_address);
    const vaultStakeContract = new web3.eth.Contract(RewardsClaimer, vault.reward_claim_addr);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json'}
    };
    
    let myReward = await fetch(OFF_CHAIN_SERVER+"rewards/authorized-rewards/"+vault.off_chain_id+"/"+eth_address,requestOptions);
    myReward = await myReward.json();
   
    myReward.claimInfo.rewardsInfo[0].amount =myReward.claimInfo.rewardsInfo[0].amount.toString();
    // myReward.claimInfo.vaultId="xx";
  let claimInfo = await vaultStakeContract.methods.claim(myReward.claimInfo,myReward.signature).send({from:eth_address});
  let finalTx = await web3.eth.getTransactionReceipt(claimInfo.transactionHash);
  callback({type:"success",data:finalTx})
}catch(error){
  callback({type:"error",message:error.message})
} 
 
  
}

export async function unStakeNfts(vault,eth_address,callback){
  const vaultStakeContract = new web3.eth.Contract(ERC721STAKING, vault.vault_addr);
  window.vaultStakeContract = vaultStakeContract;
  try{
  
    
    let stakeInfo;
    if(vault.my_stake > 25 ){
    // if(false ){
      let stakeTokenIds = await vaultStakeContract.methods.getUserInfoForPool(vault.pool_id,eth_address).call();
      let final_ids = []
      for (let index = 0; (index < 25 && index < stakeTokenIds.tokenIDs.length); index++) {
        final_ids.push(stakeTokenIds.tokenIDs[index]);
      }
      stakeInfo = await vaultStakeContract.methods.unstakeAll(vault.pool_id,final_ids).send({from:eth_address});
    }else{
       stakeInfo = await vaultStakeContract.methods.unstakeAll(vault.pool_id).send({from:eth_address});
    }

    let finalTx = await web3.eth.getTransactionReceipt(stakeInfo.transactionHash);
    callback({type:"success",data:finalTx})
  }catch(error){
    callback({type:"error",message:error.message})
  } 
}

export async function getOffChainEventLogs(vault,eth_address,callback){
  eth_address = validateAddress(eth_address);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json'}
  };
  let allReward = await fetch(OFF_CHAIN_SERVER+"rewards/vault-events/"+vault.off_chain_id,requestOptions);
  allReward = await allReward.json();
  let myReward = await fetch(OFF_CHAIN_SERVER+"rewards/vault-events-by-user/"+vault.off_chain_id+"/"+eth_address,requestOptions);
  myReward = await myReward.json();
 
  callback({allReward,myReward})

}

export async function getContractEventLogs(vault,eth_address,callback){
  const vaultStakeContract = new web3.eth.Contract(ERC721STAKING, vault.vault_addr);
  let allEvents = await vaultStakeContract.getPastEvents('allEvents', {
    filter: {},
    fromBlock: 0,
    toBlock: 'latest'
  });
  // console.log("allEvents",allEvents);
  
  for (let index = 0; index < allEvents.length; index++) {
    const v = allEvents[index];
    let a = await web3.eth.getBlock(v.blockNumber)
    allEvents[index]['timestamp'] = a.timestamp;
    // allEvents[index]['txObject'] = await web3.eth.getTransactionReceipt(v.transactionHash);;
  }
  

  // allEvents= allEvents.reverse();
  allEvents= allEvents.sort((a,b)=>a.timestamp - b.timestamp);


  callback({type:"success",data:allEvents.reverse()});
}

export async function web3signature(message,address,callback){
  try{
    var signature = await web3.eth.personal.sign(message, address);
    callback({type:"success",data:{signature}});
  }catch(error){
    callback({type:"error",message: error.message});
  }
}

export async function web3SoliditySha3(data,address,callback){
  var web3Hash;

  try{
    if(typeof data =='string'){
      web3Hash = await web3.utils.soliditySha3(data);
    }else{
      web3Hash = await web3.utils.soliditySha3(...data);
    }
    var signature = await web3.eth.personal.sign(web3Hash, address);
    // const signer = new Web3().eth.accounts.recover(
    //   web3Hash,
    //   signature,
    // );
    // alert(signer);
    callback({type:"success",data:{signature}});
  }catch(error){
    callback({type:"error",message: error.message});
  }
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  let object = { }
  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }

}


export async function walletSwitchEthereumChain(chainID,callback){
  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: getHaxChainId(chainID) }],
    });
    callback({type:"success",message:"network get switched to "+chainID});
  } catch (switchError) {
    if (switchError.code === 4902) {
      walletAddEthereumChain(chainID,(data)=>{
        if(data.type=='success'){
          walletSwitchEthereumChain(chainID,callback);
        }else{
          callback(data);
        }
      });
    }else{
      callback({type:"error",message:switchError.message});
    }
  }
  
}

export  async function addMetaMaskAssets(obj,callback){
  let assets = {
    type: 'ERC20',
    options: {
      address: obj.address,
      symbol: obj.symbol,
      decimals: obj.decimals,
      image: obj.image
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: assets,
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }


}
 
    

// helper function 

export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}

export function shortTxAddress(address){
  // let addr = web3.utils.toChecksumAddress(address);
  let addr = address;
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}


export function etherUrl(network,url) {
  let base_url = "";
  if(network.explorers.length > 0){
    base_url = network.explorers[0].url
    return base_url+url;  
  }
  

  if (ETH_ENV == 'production') {
    return "https://etherscan.io"+url;  
  } else {
    return "https://rinkeby.etherscan.io"+url; 
  }
}

export function openseaUrl(url) {
  if (ETH_ENV == 'production') {
    return "https://opensea.io"+url;  
  } else {
     return "https://testnets.opensea.io"+url; 
  }
}


export function getNetworkName(chainId){
 chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);

  if(!chain){
    chain={
      "name": "Private",
      "chainId": chainId,
      "shortName": "eth",
      "chain": "ETH",
      "network": "Private",
      "networkId": "",
      "nativeCurrency": {"name":"Ether","symbol":"ETH","decimals":18},
      "rpc": ["https://mainnet.infura.io/v3/${INFURA_API_KEY}","wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}","https://api.mycryptoapi.com/eth","https://cloudflare-eth.com"],
      "faucets": [],
      "explorers": [],
      "infoURL": "https://ethereum.org"
    }
  }
  return chain
}

export function explorerURL(chainId){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  if(chain && chain.explorers.length > 0){
    return chain.explorers[0].url+"/";
  }else{
    return "#/";
  }
} 


export function validateAddress(address){
  try{
     address = web3.utils.toChecksumAddress(address);
    if(web3.utils.checkAddressChecksum(address)){
      return address;
    } 
  } catch (error) {
  }
    return "";
  
}


export function getBlockTime(number){
  return web3.eth.getBlock(number).timestamp;
}

window.getBlockTime = getBlockTime;
window.web3Obj = web3;