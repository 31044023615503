import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TitleBar from '../../component/TitleBar'
import VaultsCard from '../../component/Vaults/VaultsCard'
import NoTransaction from '../../component/Transaction/NoTransaction'
const Vaults = (props) => {
  const {user,vaults} = props;
  const network_key = user.network.key; 
  const allVaults = vaults[network_key]

  var VaultCards = allVaults.map((vaultObj,i) => {
    return (
      <div className="col-lg-3" key={i}>
        <VaultsCard vault={vaultObj} />
      </div>
    );
  })

  return (
    <>
      <TitleBar className="title_bar" title="All Vaults" sub_title="" description="" />

      <section className="card_area py-5">
        <div className="container">
          <div className="row">

          {allVaults.length < 1 
          ?
          <NoTransaction msg_txt = "No vaults are currently available  :( " />
          :
          <>
            {VaultCards}

          
          </>
        }
         
         
          </div>
        </div>
      </section>
    </>
  )
}


Vaults.propTypes = {
  user: PropTypes.object,
  vaults: PropTypes.object,
}

const mapStateToProps = (state) => ({
  user : state.user,
  vaults : state.vaults,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Vaults);