import React, { useEffect } from "react";
import { Route, BrowserRouter, Redirect, Switch, useLocation, withRouter } from "react-router-dom";

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { LayoutOne, LayoutTwo } from "../layouts"
import Home from "../pages/Home";
import Vaults from '../pages/Vaults';
import VaultsDetails from '../pages/Vaults/details';
import Projects from "../pages/Projects";
import ProjectDetail from "../pages/Projects/details";
import Farms from "../pages/Farms";
import FarmsDetails from "../pages/Farms/details";
import ClaimReward from "../pages/ClaimReward";
import MyData from "../pages/MyData";


import Error from "../pages/Error"


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} login={rest.login}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export const Routes = (props) => {
  const { user } = props
  return (
    <BrowserRouter>
      <ScrollToTop>
        {user.login ?
          <>

            <RouteWrapper exact path="/vaults" component={Vaults} layout={LayoutOne} />
            <RouteWrapper exact path="/projects" component={Projects} layout={LayoutOne} />
            <RouteWrapper exact path="/farms" component={Farms} layout={LayoutOne} />
            <RouteWrapper exact path="/claim-reward" component={ClaimReward} layout={LayoutOne} />
            <RouteWrapper exact path="/my-data" component={MyData} layout={LayoutOne} />
            <RouteWrapper exact path="/project/:key/detail" component={ProjectDetail} layout={LayoutOne} />
            <RouteWrapper exact path="/farms/:key/details" component={FarmsDetails} layout={LayoutOne} />
            <RouteWrapper exact path="/vault/:key/details" component={VaultsDetails} layout={LayoutOne} />
            <RouteWrapper exact path="/my-vaults-farms" component={VaultsDetails} layout={LayoutOne} />
            <Redirect to="/projects" />
            <RouteWrapper exact path="/" component={Projects} layout={LayoutOne} />
          </>
          :
          <Switch>
            <RouteWrapper exact path="/" component={Home} layout={LayoutOne} />
            <RouteWrapper exact path="/error/404" component={Error} layout={LayoutOne} />
            <Redirect to='/' />
          </Switch>


        }
      </ScrollToTop>
    </BrowserRouter>
  )
}

Routes.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
